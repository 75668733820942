import '../../styles/market/store.app.css'
import '../../styles/shabes.css'
import '../../styles/leaderb/lead.fx.css'
import { transferWax, transferAsset, unpackMemo, transferEgg } from '../controllers/sendController';
import { useContext, useMemo, useState, useEffect } from 'react';
import { GlobalStateContext } from '../Contexts/stateContext';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import egg from "../../token-91.png"
import gold from "../../dcyc-gold.png"
import { buyListing } from '../controllers/missionsController';

function Leaderboard() {
  const ual = useContext(UALContext)
  const {userData, getInventory, getSignupVerification, getBalance} = useContext(GlobalStateContext)
  const [sort,setSort] = useState(0)//total:0 packs:1/mined:2/stolen:3
  const [sortedLeaders,sortLeader] = useState([])
  const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }
  
  const defaultLeader = useMemo(() => {
    sortLeader(userData.leaderboard.sort(sort_by('total_points', true, parseInt)).slice(0,200))
    return userData.leaderboard
  }, []);

  useEffect(()=>{
    if(sort === "0"){
      sortLeader(()=>userData.leaderboard.sort(sort_by('total_points', true, parseInt)).slice(0,200))
      //return userData.leaderboard.sort(sort_by('total_points', true, parseInt)).slice(0,200)
    }
    if (sort === "1"){
      //console.log(userData.leaderboard.sort(sort_by('gold_mined', true, parseInt)).slice(0,200))
      sortLeader(()=>userData.leaderboard.sort(sort_by('packs_stolen', true, parseInt)).slice(0,200))
    }
    if (sort === "2"){
      sortLeader(()=>userData.leaderboard.sort(sort_by('gold_mined', true, parseFloat)).slice(0,200))
    }
    if (sort === "3"){
      sortLeader(()=>userData.leaderboard.sort(sort_by('gold_stolen', true, parseFloat)).slice(0,200))
    }
  },[sort,userData.leaderboard])


  const fullLeaderboard = useMemo(() => {

    return userData.leaderboard.sort(sort_by('total_points', true, parseInt))
  }, [userData.leaderboard]);

  function handleChange(event) {
    setSort(event.target.value)
    console.log("sort id:",sort)
  }
  // Sort by total points
  //<img src="image/leaderb/one.png"  style={{width:"40px"}}/>
    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">Your rank on the league table is #{fullLeaderboard.findIndex((row)=>row.user===userData.username)||0} and total score: {fullLeaderboard.find((row)=>row.user===userData.username)?fullLeaderboard.find((row)=>row.user===userData.username).total_points:0} ! Leaderboard updates daily </ol>
          </div>
          <div className="tabs_wrap">
            <ul>
              <li data-tabs="player" className="active">
                <i className="fa-solid fa-feather-pointed"></i> Player
              </li>
              <li data-tabs="pvp">
                <i className="fa-brands fa-firefox-browser"></i> PVP
              </li>
              <li data-tabs="alphawl">
                <i className="fa-solid fa-square-poll-vertical"></i> Alpha WL
              </li>
            </ul>
            <div className="server_op">
              <select value={sort} onChange={handleChange}>
                <option value={0}>Total Score</option>
                <option value={1}>Packs Stolen</option>
                <option value={2}>Gold Mined</option>
                <option value={3}>Gold Stolen</option>
              </select>
            </div>
          </div>
          <div event="" className="banner-ld">
            <a event="" className="ld-item" href="https://www.dcyourchickens.io/">
              <div event="" className="item-inner">
                <div event="" className="banner-bg" style={{backgroundImage:"url(/image/leaderb/event_game.jpg)"}}></div>
                <div event="" className="stage-title">WAXDCYC Event</div>
                <div event="" className="title">
                </div>
                <div event="" className="delay-tips">
                  <div event="" className="delay-title">Ends at</div>
                  <div event="" className="delay-items">
                    <div event="" className="delay-item">
                      <span event="" className="num">167</span>
                    </div>
                    <div event="" className="delay-item"><span event="" className="sep">:</span><span event="" className="num">59</span></div>
                    <div event="" className="delay-item"><span event="" className="sep">:</span><span event="" className="num">30</span></div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="leaderhv">
            <div className="tbwrapper_2w">
              <div className="row">
                <div className="col-lg-12 col-xl-6  col-md-12">
                  <div className="root_Zf9">
                    <table className="table_5rg">
                      <thead className="head_2dt">
                        <tr className="tr_ne">
                          <th className="th_24hy"> #</th>
                          <th className="th_24hy"> Player</th>
                          <th className="th_24hy"> Packs/Gold Stolen</th>
                          <th className="th_24hy"> Gold Mined</th>
                        </tr>
                      </thead>
                      {
                        sortedLeaders.slice(0,100).map((row,index)=>(
                          <tbody key={row.user} className="body_3W">
                        <tr className="tr_T2">
                          <td className="td_27ro">
                            <div className="table_wp1D">
                              {
                              ((index===0)&& <img src="image/leaderb/one.png"  style={{width:"40px"}}/>)||
                              ((index===1)&& <img src="image/leaderb/two.png"  style={{width:"40px"}}/>)||
                              ((index===2)&& <img src="image/leaderb/three.png"  style={{width:"40px"}}/>)||
                              ((index>2)&& index+1)
                              }
                              
                            </div>
                          </td>
                          <td className="td_27ro">
                            <div className="table_wp1D user_name_fx">
                              <div className="circle_5w userimg_ tavb_3P">
                                <div className="wrapper_1Q">
                                  <img alt="avatar" src="image/profile/rooster_.png" className="image_2RN opit_1H"/>
                                </div>
                              </div>
                              <span className="wallet__dcyc">{row.user}</span>
                            </div>
                          </td>
                          <td className="td_27ro">{/* packs_stolen/gold_stolen */row.packs_stolen+"/"+Math.round(row.gold_stolen)}</td>
                          <td className="td_27ro">{/* gold_mined*/Math.round(row.gold_mined)}</td>
                        </tr>
                      </tbody>
                        ))
                      }
                      
                    </table>
                  </div>
                </div>

                <div className="col-lg-12 col-xl-6  col-md-12">
                  <div className="root_Zf9">
                    <table className="table_5rg">
                    <thead className="head_2dt ddn">
                        <tr className="tr_ne">
                          <th className="th_24hy hid"> #</th>
                          <th className="th_24hy hid"> Player</th>
                          <th className="th_24hy hid"> Packs/Gold Stolen</th>
                          <th className="th_24hy hid"> Gold Mined</th>
                        </tr>
                      </thead>
                      {
                        sortedLeaders.slice(100,200).map((row,index)=>(
                          <tbody key={row.user} className="body_3W">
                        <tr className="tr_T2">
                          <td className="td_27ro">
                            <div className="table_wp1D">
                              {
                              index+101
                              }
                              
                            </div>
                          </td>
                          <td className="td_27ro">
                            <div className="table_wp1D user_name_fx">
                              <div className="circle_5w userimg_ tavb_3P">
                                <div className="wrapper_1Q">
                                  <img alt="avatar" src="image/profile/rooster_.png" className="image_2RN opit_1H"/>
                                </div>
                              </div>
                              <span className="wallet__dcyc">{row.user}</span>
                            </div>
                          </td>
                          <td className="td_27ro">{/* packs_stolen/gold_stolen */row.packs_stolen+"/"+Math.round(row.gold_stolen)}</td>
                          <td className="td_27ro">{/* gold_mined*/Math.round(row.gold_mined)}</td>
                        </tr>
                      </tbody>
                        ))
                      }
                      
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  export default Leaderboard;