import '../../styles/inventory/backpack.css'
import '../../styles/mission_page.css'
import Pagination from '../Pagination/Pagination';
import { GlobalStateContext } from '../Contexts/stateContext';
import { claimstolenAction } from '../controllers/sendController';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { stakeAssets } from '../controllers/sendController';
import { useHorizontalScroll } from '../Hooks/useSideScroll';
import { bulkUnstake, bulkCollectTax, collectTax, queueUnstake, cancelUnstake, levelup, claimLevelup, speedLevelup, repair } from '../controllers/missionsController';
import Countdown from '../Countdown/Countdown';
import { useNavigate } from "react-router-dom";
import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
const TWO_SLOTS_TEMPLATE = "618025"
const FOUR_SLOTS_TEMPLATE = "618026"
const EIGHT_SLOTS_TEMPLATE = "618027"
//north,south,east,west



function Jetpack({onValue,withWeapon,jetpack}) {
const [jetpackType,setJetpack] = useState(jetpack)
const [twoSlots,updateTwoSlots] = useState([null,null])
const [weapon,setWeapon] = useState(withWeapon)
const [fourSlots,updateFourSlots] = useState([null,null,null,null])

const [eightSlots,updateEightSlots] = useState([null,null,null,null,null,null,null,null])
useEffect(()=>{
 setJetpack(jetpack)
 setWeapon(withWeapon)
}
,[jetpack,withWeapon])


useEffect(() => { 
    if (jetpackType === TWO_SLOTS_TEMPLATE)onValue(twoSlots)
    if (jetpackType === FOUR_SLOTS_TEMPLATE)onValue(fourSlots)
    if (jetpackType === EIGHT_SLOTS_TEMPLATE)onValue(eightSlots)
    
}, [twoSlots,fourSlots,eightSlots])
//onClick={()=>navigate(`/asset/${item.asset_id}`)}
function changeSlots(index,slotType,way){
    if (slotType===2){
        const arr = [... twoSlots]
        arr[index] = way
        updateTwoSlots(arr)
    }
    if (slotType===4){
        const arr = [... fourSlots]
        arr[index] = way
        updateFourSlots(arr)
    }
    if (slotType===8){
        const arr = [... eightSlots]
        arr[index] = way
        updateEightSlots(arr)
    }
}
    return (

        <div className="container-fluid">
            {
                
                (jetpackType === TWO_SLOTS_TEMPLATE && weapon === false)?
                    (<>
                <div className="row p-2 mb-2 gx-2">
                    <div className={["btn","col",twoSlots[0] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(0,2,"north")}>north</div>
                    <div className={["btn","col",twoSlots[0] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(0,2,"south")}>south</div>
                    <div className={["btn","col",twoSlots[0] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(0,2,"east")}>east</div>
                    <div className={["btn","col",twoSlots[0] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(0,2,"west")}>west</div>
                </div>
                <div className="row p-2 mb-2 gx-2">
                    <div className={["btn","col",twoSlots[1] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(1,2,"north")}>north</div>
                    <div className={["btn","col",twoSlots[1] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(1,2,"south")}>south</div>
                    <div className={["btn","col",twoSlots[1] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(1,2,"east")}>east</div>
                    <div className={["btn","col",twoSlots[1] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(1,2,"west")}>west</div>
                </div>
                </>):null
                
            }
            {
                (jetpackType === FOUR_SLOTS_TEMPLATE && withWeapon === false) && 
                (
                    <div className="row p-2">
                    <div className="mb-2 row">
                    <div className={["col","btn",fourSlots[0] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(0,4,"north")}>north</div>
                    <div className={["col","btn",fourSlots[0] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(0,4,"south")}>south</div>
                    <div className={["col","btn",fourSlots[0] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(0,4,"east")}>east</div>
                    <div className={["col","btn",fourSlots[0] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(0,4,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",fourSlots[1] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(1,4,"north")}>north</div>
                    <div className={["col","btn",fourSlots[1] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(1,4,"south")}>south</div>
                    <div className={["col","btn",fourSlots[1] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(1,4,"east")}>east</div>
                    <div className={["col","btn",fourSlots[1] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(1,4,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",fourSlots[2] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(2,4,"north")}>north</div>
                    <div className={["col","btn",fourSlots[2] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(2,4,"south")}>south</div>
                    <div className={["col","btn",fourSlots[2] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(2,4,"east")}>east</div>
                    <div className={["col","btn",fourSlots[2] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(2,4,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",fourSlots[3] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(3,4,"north")}>north</div>
                    <div className={["col","btn",fourSlots[3] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(3,4,"south")}>south</div>
                    <div className={["col","btn",fourSlots[3] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(3,4,"east")}>east</div>
                    <div className={["col","btn",fourSlots[3] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(3,4,"west")}>west</div>
                </div>
                    </div>
                    )
            }
            {
                (jetpackType === EIGHT_SLOTS_TEMPLATE && withWeapon === false) && 
                (
                <div className="row">
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[0] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(0,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[0] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(0,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[0] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(0,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[0] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(0,8,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[1] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(1,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[1] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(1,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[1] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(1,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[1] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(1,8,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[2] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(2,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[2] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(2,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[2] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(2,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[2] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(2,8,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[3] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(3,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[3] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(3,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[3] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(3,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[3] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(3,8,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[4] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(4,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[4] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(4,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[4] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(4,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[4] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(4,8,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[5] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(5,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[5] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(5,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[5] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(5,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[5] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(5,8,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[6] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(6,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[6] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(6,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[6] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(6,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[6] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(6,8,"west")}>west</div>
                </div>
                <div className="mb-2 row">
                    <div className={["col","btn",eightSlots[7] === "north"?"selected":""].join(" ")} onClick={()=>changeSlots(7,8,"north")}>north</div>
                    <div className={["col","btn",eightSlots[7] === "south"?"selected":""].join(" ")} onClick={()=>changeSlots(7,8,"south")}>south</div>
                    <div className={["col","btn",eightSlots[7] === "east"?"selected":""].join(" ")} onClick={()=>changeSlots(7,8,"east")}>east</div>
                    <div className={["col","btn",eightSlots[7] === "west"?"selected":""].join(" ")} onClick={()=>changeSlots(7,8,"west")}>west</div>
                </div>
                </div>
                )
            }
            
            {
                (jetpackType === TWO_SLOTS_TEMPLATE && withWeapon === true) && 
                (
                <div className="row">
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                </div>
                )
            }
            {
                (jetpackType === FOUR_SLOTS_TEMPLATE && withWeapon === true) && 
                (
                <div className="row">
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                </div>
                )
            }
            {
                (jetpackType === EIGHT_SLOTS_TEMPLATE && withWeapon === true) && 
                (
                <div className="row">
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                <div className="row">
                    <div className="col border">weapon mission</div>
                </div>
                </div>
                )
            }
          
            
        </div>

    );
  }
  export default Jetpack;