import { useContext,useState,useEffect,useMemo } from 'react';
import '../../styles/inventory/backpack.css'
import { GlobalStateContext } from '../Contexts/stateContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { stakeAssets } from '../controllers/sendController';
import { useHorizontalScroll } from '../Hooks/useSideScroll';
import { UALContext } from 'ual-reactjs-renderer';
import Pagination from '../Pagination/Pagination';
import Countdown from '../Countdown/Countdown';
import { useNavigate } from "react-router-dom";
import Jetpack from './Jetpack';
import { bulkUnstake, bulkCollectTax, collectTax, queueUnstake, levelup, speedLevelup, bulkMissionStart, bulkMissionGo, bulkMissionComplete, bulkMissionCollect, claimLevelup, cancelUnstake, missionStart, missionCancel, bulkMissionGoWeapon } from '../controllers/missionsController';

const CHICKEN_TEMPLATES=[
  "583633","601886","601889","601890","601899","601919","601925","601926","601929","601930","601933","601934","601935","601936","601937","601938","601939",//class 1 chicken
  "611252","611251","611250","611249","611248","611247","611246","611245","611227","611239","611240","611241","611242","611243","611244","611237",//class 2 chicken
  "620591","620592","620593","620594","620595","620597","620598","620599",//class 3 chicken
  "626936","626937","626939","626940","626941","626943","626945","626946",//class 4 chicken
  "643579","643580","643582","643584","643587","643588","643589","643590"//class 5 chicken
]
const ELITES=["601930","601933","601934","601935","601936","601937","601938","601939",//class 1 chicken elite
"611252","611251","611250","611249","611248","611247","611246","611245"//class 2 chicken elite
]
const SweetalertModal = ({onValue,array,callback,ipfs}) =>{
  const [selected,setSelection] = useState([])
  useEffect(() => { onValue(selected) }, [selected])
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array.filter(item=> CHICKEN_TEMPLATES.find((chicken)=>item.template.template_id===chicken) )
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center">
                  <div onClick={()=>{selected.includes(item.asset_id)?setSelection(selected.filter((element)=>element!==item.asset_id)):setSelection([...selected,item.asset_id])}} className={["nft-container",selected.includes(item.asset_id)?"selected":""].join(" ")}>
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  </div>
                  <span>#{item.asset_id}</span>
              </div>)
              })
              }
          </div>
          </div>
  );
}

const UnstakeModal = ({onValue,array,callback,ual,refreshFunction,ipfs}) =>{
  const [selected,setSelection] = useState([])
  useEffect(() => { onValue(selected) }, [selected])
  function update(){console.log("timer update")}
  function cancel(asset_id){
    cancelUnstake(ual,asset_id)
    .then(()=>Swal.fire({
      position: "center",
      icon: "success",
      title: "Processing transaction!",
      showConfirmButton: !1,
      timer: 2500
  }))
    .then(()=>refreshFunction())
  }
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array.filter(item=>CHICKEN_TEMPLATES.find((chicken)=>item.template.template_id===chicken) && item.availability.is_busy === 0)
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center">
                  <div style={{"position":"relative"}} onClick={()=>{selected.find((asset)=>asset.asset_id === item.asset_id)?setSelection(selected.filter((element)=>element.asset_id!==item.asset_id)):setSelection([...selected,{asset_id:item.asset_id,availability:item.availability}])}} className={["nft-container",selected.find((asset)=>asset.asset_id === item.asset_id)?"selected":""].join(" ")}>
                    {item.availability.is_queued===1 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000) &&
                    <i style={{"position":"absolute","fontSize":"35px","color":"darkgreen"}} className="fa-solid fa-check">
                    </i>
                    }
                    {item.availability.is_queued===1 && Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000) &&
                    <i style={{"position":"absolute","fontSize":"35px","color":"sandybrown"}} className="fa-solid fa-angles-left">
                    </i>
                    }
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  
                  </div>
                  <span>#{item.asset_id}</span>
                  <Countdown callback={update} sec={Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)}/>
                  {item.availability.is_queued===1&&<div onClick={()=>cancel(item.asset_id)} className="btn btn-primary">Cancel queue</div>}
              </div>)
              })
              }
          </div>
          </div>
  );
}

const MissionSelectModal = ({onValue,array,callback}) =>{
  const [selected,setSelection] = useState({})
  useEffect(() => { onValue(selected)}, [selected])
  //{mission.allowlist[0][0].level_req}
  return (
    <div className="row row-cols-1 row-cols-sm-2 missions-row gx-5 gy-5 ps-4 pe-4 pt-2 pb-2">
    {array.map(mission=>{ 

      return (
        <div key={mission.mission_id} className="mission col text-center ">
          <div onClick={()=>setSelection(mission)} className={["h-100","pb-1",selected.mission_id===mission.mission_id?"selected":""].join(" ")}>
            <span className='h4'>{mission.info[0].value}</span>
            <hr />
            <div>Level: chicken lvl</div>
            <div>Mission duration:{mission.mission_duration <=3600?Math.floor( mission.mission_duration/60)+" min":Math.floor(mission.mission_duration/3600)+" hours"}</div>
            <div>Mission cost: {mission.mission_cost[0].quantity.split(" ")[0]>0?mission.mission_cost[0].quantity.split(" ")[0]+" GOLD":"Free"} {mission.mission_cost.length>1&&"+ "+mission.mission_cost[1].quantity}</div>
            <div>Reward: {mission.mission_reward_success[0].quantity.split(" ")[0]} GOLD</div>
            <div>Mission success rate: {(mission.mission_odds===0 && mission.mission_odds_total===0)?100:Math.floor(mission.mission_odds/mission.mission_odds_total*100)} %</div>
            <div>Stolen if mission fail: {(mission.pools_fail.length===0)?0:mission.pools_fail[0].asset.quantity.split(" ")[0] + " GOLD"}</div>
            
            
          </div>
        </div>
      )
      
      })}
  </div>
  );
}

const EquipModal = ({onValue,array,callback,ipfs}) =>{
  const [selected,setSelection] = useState(null)
  useEffect(() => { onValue(selected) }, [selected])
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center" style={{"position":"relative"}}>
                  <div onClick={()=>setSelection(item.asset_id)} className={["nft-container",selected===item.asset_id?"selected":""].join(" ")}>
                    <div className="level" style={{"position":"absolute"}}>{item.data.level!==undefined?item.data.level:0}</div>
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  </div>
                  <span>#{item.asset_id}</span>
              </div>)
              })
              }
          </div>
          </div>
  );
}

const JetpackModal = ({onValue,array,callback,ipfs}) =>{
  const [selected,setSelection] = useState(null)
  useEffect(() => { onValue(selected) }, [selected])
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center" style={{"position":"relative"}}>
                  <div onClick={()=>setSelection(item)} className={["nft-container",selected!==null && selected.asset_id===item.asset_id?"selected":""].join(" ")}>
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  </div>
                  <span>#{item.asset_id}</span>
              </div>)
              })
              }
          </div>
          </div>
  );
}


const ALLOWED_TEMPLATES="583633"


let PageSize = 7;
// <button onClick={()=>initStealAction(item.data.weight_start,item.asset_id)} className={["fa-solid","fa-box-open",userData.stolennfts.some(({start_weight})=> start_weight==item.data.weight_start)?"available-to-steal":""].join(" ")}></button>
function WorkspacePage() {
  const {userData,getInventory,getStakedAssets,getSignupVerification,getUnclaimedRewards} = useContext(GlobalStateContext)
  const [completeCountdowns,addCompleteCountdown] = useState(0)
  const [currentTime,updateCurrentTime] = useState(new Date().getTime()/1000)
  const [selectedChickens,selectChicken] = useState([])
  const ual = useContext(UALContext)
  const SelectorModal = withReactContent(Swal)
  const navigate = useNavigate()
  //const availableChickens = userData.inventory.filter(item=>item.template.template_id=="452878") // change dynamic templates
  let selectedFromModal;

  const [currentPage, setCurrentPage] = useState(1);
  const [stakedChickens ,setStakedChickens] = useState([])
  const chickensOnMission = userData.stakedChickens.filter((asset)=>asset.associatedMission!==undefined)
  const currentTableData = useMemo(() => {

    return userData.stakedChickens.filter(asset=>CHICKEN_TEMPLATES.find(chicken=>asset.template.template_id===chicken) );
  }, [userData.stakedChickens,completeCountdowns]);
  useEffect(()=>{
    setStakedChickens(userData.stakedChickens.filter(asset=>ELITES.find(chicken=>asset.template.template_id===chicken) ))
  },[userData.stakedChickens])
  let collectEggs = (asset_id) =>{
    collectTax(ual,asset_id).then(()=>getSignupVerification()).then(()=>getStakedAssets())
  }

  useEffect(()=>{
    updateCurrentTime(new Date().getTime()/1000)
  },[completeCountdowns])

  let showTaxError = () =>{
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Current asset is busy",
      showConfirmButton: !1,
      timer: 1500
  })
  }

  let showlvlupError = () =>{
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Asset is maxed",
      showConfirmButton: !1,
      timer: 1500
  })
  }

  let openUnstakeModal = () =>{
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<UnstakeModal ipfs={userData.ipfs} ual={ual} refreshFunction={getStakedAssets} onValue={value => { selectedFromModal = value }} array={userData.stakedChickens}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal.length>0){
        let to_unstake = selectedFromModal.filter((item)=>(item.availability.is_queued === 1 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)))
        let to_queue = selectedFromModal.filter((item)=>(item.availability.is_queued === 0 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)))
        console.log("queue",to_queue)
        console.log("unstake",to_unstake)
        if(to_queue.length>0){
          let assets = to_queue.map((item)=>item.asset_id)
          queueUnstake(ual,assets)
          .then(()=>Swal.fire({
            position: "center",
            icon: "success",
            title: "Assets sent to unstake queue",
            showConfirmButton: !1,
            timer: 1500
        }))
          .then(()=>getStakedAssets())
        }
        if(to_unstake.length>0){
          let assets = to_unstake.map((item)=>item.asset_id)
        bulkUnstake(ual,assets)
        .then(()=>getStakedAssets())
        .then(()=>getInventory())
        }
        
      }
      }
    })
  }

  let openJetpackModal = (jetpack) =>{
    let weap = false
    SelectorModal.fire({
      title: 'Choose mission!',
      html:(<Jetpack onValue={value => { selectedFromModal = value }} withWeapon={weap} jetpack={jetpack.template.template_id}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal[0]!==null){
          console.log(selectedFromModal)
          let missionsStack = selectedFromModal;
          let _Eggs = 0;
          let _Wax = 0;
          let costsArray = [];
          selectedFromModal.forEach((selection)=>{
            let missionName = selection[0].toUpperCase()+selection.slice(1)
            console.log(missionName)
            let _missionsCost = selectedChickens.map((chicken)=> {return {
              mission_cost:userData.missionsList.find((mission)=>String(mission.allowlist[0][0].template_id)===chicken.template.template_id && mission.allowlist[0][0].level_req === chicken.data.level && mission.info[0].value === missionName).mission_cost
            } 
            })
            costsArray = costsArray.concat(_missionsCost)
          })
          
            let _totalEgg = costsArray.map((costRow)=>{
              return costRow.mission_cost.find((item)=>item.contract==="dcyctokeneos").quantity.split(" ")[0]})
            let _totalWax = costsArray.map((costRow)=>{
              return costRow.mission_cost.find((item)=>item.contract==="eosio.token")?costRow.mission_cost.find((item)=>item.contract==="eosio.token").quantity.split(" ")[0]:0})
            _Eggs = _totalEgg.reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
            _Wax = _totalWax.reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
            console.log(_Eggs.toFixed(8),_Wax.toFixed(8))
          Swal.fire({
            title:"Confirmation",
            text:`are you sure you want to start missions, total cost:${_Eggs.toFixed(8)} GOLD + ${_Wax.toFixed(8)} WAX`,
            icon:"question",
            preConfirm: ()=>{
              //weapons+jetpack
              //
              let to_start = selectedChickens.filter((selected)=>selected.associatedMission===undefined)
              console.log(to_start)
              let to_start_go = to_start.map((chicken)=> {return {
                mission_ids:missionsStack.map(way=>
                  userData.missionsList.find((mission)=>String(mission.allowlist[0][0].template_id)===chicken.template.template_id && mission.allowlist[0][0].level_req === chicken.data.level && mission.info[0].value ===way[0].toUpperCase()+way.slice(1)).mission_id
                ),
                user_mission_id:chicken.asset_id,
                weapon_nft:jetpack.asset_id
              }
              })

              let filtered_to_go = selectedChickens.filter((selected)=>selected.associatedMission!==undefined && selected.associatedMission.asset_ids.length===1)
                  let to_go = filtered_to_go.map((chicken)=> {return {asset_id:chicken.asset_id,
                    mission_ids: (missionsStack.length + chicken.associatedMission.completed_missions.length<=8)?missionsStack.map(way=>
                      userData.missionsList.find((mission)=>String(mission.allowlist[0][0].template_id)===chicken.template.template_id && mission.allowlist[0][0].level_req === chicken.data.level && mission.info[0].value ===way[0].toUpperCase()+way.slice(1)).mission_id
                    ):missionsStack.slice(0,8-chicken.associatedMission.completed_missions.length).map(way=>
                      userData.missionsList.find((mission)=>String(mission.allowlist[0][0].template_id)===chicken.template.template_id && mission.allowlist[0][0].level_req === chicken.data.level && mission.info[0].value ===way[0].toUpperCase()+way.slice(1)).mission_id
                    ),
                    user_mission_id:chicken.asset_id,
                    weapon_nft:jetpack.asset_id
                  }
                  })

                  let filtered_to_use = selectedChickens.filter((selected)=>selected.associatedMission!==undefined && selected.associatedMission.asset_ids.length>1)
                  let to_use_weapon = filtered_to_use.map((chicken)=> {return {
                    //weapon_nft:chicken.associatedMission.asset_ids[1],
                    //user_mission_id:chicken.asset_id,
                    asset_id:chicken.asset_id,
                    user_mission_id:chicken.asset_id,
                    weapon_nft:jetpack.asset_id,
                    mission_ids:(missionsStack.length + chicken.associatedMission.completed_missions.length<=8)?missionsStack.map(way=>
                      userData.missionsList.find((mission)=>chicken.associatedMission!==undefined && chicken.associatedMission.asset_ids.length>1 && mission.allowlist[1]!==undefined && mission.allowlist[1][0]!==undefined && String(mission.allowlist[1][0].template_id)=== String(userData.stakedChickens.find(weapon=>weapon.asset_id === chicken.associatedMission.asset_ids[1]).template.template_id) && mission.allowlist[1][0].level_req === userData.stakedChickens.find(weapon=>weapon.asset_id === chicken.associatedMission.asset_ids[1]).data.level && mission.allowlist[0][0].level_req === chicken.data.level && String(mission.allowlist[0][0].template_id)===chicken.template.template_id /*&& mission.info[0].value ===_selectedMission.info[0].value*/).mission_id
                    ):missionsStack.slice(0,8-chicken.associatedMission.completed_missions.length).map(way=>
                      userData.missionsList.find((mission)=>chicken.associatedMission!==undefined && chicken.associatedMission.asset_ids.length>1 && mission.allowlist[1]!==undefined && mission.allowlist[1][0]!==undefined && String(mission.allowlist[1][0].template_id)=== String(userData.stakedChickens.find(weapon=>weapon.asset_id === chicken.associatedMission.asset_ids[1]).template.template_id) && mission.allowlist[1][0].level_req === userData.stakedChickens.find(weapon=>weapon.asset_id === chicken.associatedMission.asset_ids[1]).data.level && mission.allowlist[0][0].level_req === chicken.data.level && String(mission.allowlist[0][0].template_id)===chicken.template.template_id /*&& mission.info[0].value ===_selectedMission.info[0].value*/).mission_id
                    )
                  }
                  })
  

                jetpackAssignProcess(to_start,to_go,to_use_weapon,to_start_go).then(()=>
                Swal.fire({
                  title: 'Processing transaction!',
                  timer: 5000,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  timerProgressBar: false,
                  didOpen: () => {
                    Swal.showLoading()
                  },
                })
                .then(()=>getStakedAssets())
                .then(()=>getSignupVerification())
                )
               
            }
          })

          }
      }
    })
  }

  let chooseJetpackModal = () =>{
    let weapons = userData.stakedChickens.filter((asset)=>asset.template.template_id == "618025" || asset.template.template_id == "618026" || asset.template.template_id == "618027")
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<JetpackModal ipfs={userData.ipfs} onValue={value => { selectedFromModal = value }} array={weapons}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal!==null && selectedFromModal!==undefined){
        console.log("selected")
        openJetpackModal(selectedFromModal)
        }
      }
    })
  }
  

  let openLvlModal = (asset_id,lvl,item) =>{
    //let lvl_object = userData.upgradeList.find((lvl_row)=>lvl_row.level_requirement === lvl && String(lvl_row.template_id)===item.template.template_id)
    if(item.availability.is_busy===1||item.availability.is_queued===1){
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Upgrade unavailable! Chicken on a mission/in unstake queue",
        showConfirmButton: !1,
        timer: 1500
      })
    }
    if(item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)){
      let lvls_object_array = []
      let total_lvl = 0
      let total_cost = 0
      let total_time_sec = 0
      Swal.fire({
        position: "center",
        icon: "question",
        title: "Choose level to upgrade",
        input: "range",
        inputAttributes: {
        min: lvl+1,
        max: 200,
        step: 1
    },
    inputValue: lvl+1,
    preConfirm: (input)=>{
      for(let i = lvl;i<input;i++){
        let lvl_object = userData.upgradeList.find((lvl_row)=>lvl_row.level_requirement === i && String(lvl_row.template_id)===item.template.template_id)
        total_cost = total_cost+ Number(lvl_object.cost[0].quantity.split(" ")[0])
        total_time_sec = total_time_sec+lvl_object.level_duration
        lvls_object_array.push(lvl_object)
      }
      total_lvl=input
      console.log(lvls_object_array)
    }
      }).then((result)=>result.isConfirmed&&
      
      Swal.fire({
        position: "center",
        icon: "question",
        title: "Are you sure you want to lvl up?",
        text: `lvl ${lvl} -> ${total_lvl} | cost: ${total_cost.toFixed(8)} GOLD | time: ${total_time_sec>60?Math.round(total_time_sec/60)+" min":total_time_sec+" sec"}`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: (input)=>{
          //console.log(lvls_object_array.map((row)=>{return row.level_up_id}))
          let lvls_arr = lvls_object_array.map((row)=>{return row.level_up_id})
          if(userData.internalEggcoinsBalance>=total_cost){
            levelup(ual,item.asset_id,lvls_arr)
            //add 2sec delay
            .then(()=>
                      Swal.fire({
                        title: 'Processing transaction!',
                        timer: 4000,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        timerProgressBar: false,
                        didOpen: () => {
                          Swal.showLoading()
                        },
                      })
                      )
            .then(()=>getSignupVerification())
            .then(()=>getStakedAssets())
          }
          else{
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Not enough GOLD",
              showConfirmButton: !1,
              timer: 1500
            })
          }
        }
      })
      )

  }

  if(item.availability.is_busy===0&&item.availability.is_queued===0&&(item.data.level_queue!==undefined&&item.data.level_queue.length>=0 && item.data.upgrade_available_at>0)&&Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)&&Math.round(new Date().getTime()/1000)>item.data.upgrade_available_at){
    claimLevelup(ual,item.asset_id)
    .then(()=>
                    Swal.fire({
                      title: 'Processing transaction!',
                      timer: 6000,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      allowEnterKey: false,
                      timerProgressBar: false,
                      didOpen: () => {
                        Swal.showLoading()
                      },
                    })
                    )
          .then(()=>getSignupVerification())
          .then(()=>getStakedAssets())
  }

  if(lvl>0&&item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)&&Math.round(new Date().getTime()/1000)<=item.data.upgrade_available_at){
    let previous_lvl_object = userData.upgradeList.find((lvl_row)=>lvl_row.level_up_id === Number(item.data.level_queue[0]))
    Swal.fire({
      position: "center",
      icon: "question",
      title: "Are you sure you want to speed up leveling?",
      text: `skip time: ${previous_lvl_object.level_duration} sec | cost: ${previous_lvl_object.speed_up_cost[0].quantity}`,
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: (input)=>{
        if(userData.internalEggcoinsBalance>=previous_lvl_object.speed_up_cost[0].quantity.split(" ")[0]){
          speedLevelup(ual,item.asset_id,previous_lvl_object.level_up_id)
          //add 2sec delay
          .then(()=>
                    Swal.fire({
                      title: 'Processing transaction!',
                      timer: 4000,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      allowEnterKey: false,
                      timerProgressBar: false,
                      didOpen: () => {
                        Swal.showLoading()
                      },
                    })
                    )
          .then(()=>getSignupVerification())
          .then(()=>getStakedAssets())
        }
        else{
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Not enough GOLD",
            showConfirmButton: !1,
            timer: 1500
          })
        }
      }
    })
  }


  }

  let eliteClaim = (template,asset_id) =>{
    if (ELITES.find((temp)=>temp===template)){
      collectTax(ual,asset_id).then(()=>getSignupVerification()).then(()=>getStakedAssets())
    }
    else{
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Cant claim withdrawal fees with current asset",
        showConfirmButton: !1,
        timer: 1500
    })
    }
  }

  let showWithdrawalFeeClaimError = () =>{
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Claim is not ready",
      showConfirmButton: !1,
      timer: 1500
  })
  }

  let openStakeModal = () => {
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<SweetalertModal ipfs={userData.ipfs} onValue={value => { selectedFromModal = value }} array={userData.inventory}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal.length>0){
        stakeAssets(ual,selectedFromModal)
        .then(()=>getStakedAssets())
        .then(()=>getInventory())}
      }
    })
  }

  let unequipModal = (user_mission_id) => {
    Swal.fire({
      icon:'question',
      text: 'Are you sure you want unequip current item?',
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText:"No",
      preConfirm: (input)=>{
        if(input){
        missionCancel(ual,user_mission_id)
        .then(()=>
        Swal.fire({
          title: 'Processing transaction!',
          timer: 3000,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }))
        .then(()=>getStakedAssets())
        .then(()=>getInventory())}
      }
    })
  }

  let openEquipModal = (chicken) => {
    //chicken_lvl>125
    //rarity check asset vs weapon
    //[]
    //618020 - uncommon
    //618021 - rare
    //618022 - epic
    //618023 - legendary
    //618024 - mythic
    //618025 - jetpack2
    //618026 - jetpack4
    //618027 - jetpack8
    let lvlCheck = chicken.data.level>=125?true:false
    let alwaysVisible = ["618020","618025","618026","618027","626582"]
    let concated = []
    if (chicken.data.level>=125){
      concated = concated.concat(alwaysVisible)
      if (chicken.data.level>=130){concated = concated.concat(["610821","626637"])}
      if (chicken.data.level>=135){
        if ((chicken.data.rarity===undefined && ["601889","601890","601933","601934","601899","601919","601935","601936","601925","601926","601937","601938","601929","601939"].find(i=>i===chicken.template.template_id)) || (chicken.data.rarity === "Rare" ||chicken.data.rarity === "Epic"||chicken.data.rarity === "Legendary"||chicken.data.rarity === "Mythic")){concated = concated.concat(["610822","626907"])}
      }
      if (chicken.data.level>=140){
        if((chicken.data.rarity===undefined && ["601899","601919","601935","601936","601925","601926","601937","601938","601929","601939"].find(i=>i===chicken.template.template_id)) || chicken.data.rarity === "Epic"||chicken.data.rarity === "Legendary"||chicken.data.rarity === "Mythic"){concated = concated.concat(["618023","626910"])}

      }
      if (chicken.data.level>=145){
        if((chicken.data.rarity===undefined && ["601925","601926","601937","601938","601929","601939"].find(i=>i===chicken.template.template_id)) || chicken.data.rarity === "Legendary"||chicken.data.rarity === "Mythic"){concated = concated.concat(["618024","626912"])}

      }

    }
    let weapons = userData.stakedChickens.filter((asset)=>((asset.isWeapon===true)&&(asset.associatedMission==undefined)&&(asset.availability.is_busy===0)&&(concated.find(id=>asset.template.template_id===id)&& (asset.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(asset.availability.available_at+"Z").getTime()/1000)))))

    SelectorModal.fire({
      title: 'Choose weapon to equip!',
      html:(<EquipModal ipfs={userData.ipfs} onValue={value => { selectedFromModal = value }} array={weapons}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal!==null && selectedFromModal!==undefined){
        missionStart(ual,[chicken.asset_id,selectedFromModal])
        .then(()=>Swal.fire({
          title: 'Processing transaction!',
          timer: 3000,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }))
        .then(()=>getStakedAssets())
        .then(()=>getInventory())}
      }
    })
  }

 async function massAssignProcess(to_start,to_go,to_use_weapon,to_start_go){
  if(to_start.length>0 ){
    await bulkMissionStart(ual,to_start.map((item)=>{return item.asset_id}))
    .then(()=>bulkMissionGo(ual,to_start_go))
   /* .then(()=>
    Swal.fire({
      title: 'Processing transaction!',
      timer: 1500,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    )
    .then(()=>getStakedAssets())
    .then(()=>getSignupVerification())*/
  }
  if(to_go.length>0 ){
    await bulkMissionGo(ual,to_go)
   /* .then(()=>
    Swal.fire({
      title: 'Processing transaction!',
      timer: 1500,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getStakedAssets())
    .then(()=>getSignupVerification())*/
  }
  
  if(to_use_weapon.length>0 ){
    await bulkMissionGo(ual,to_use_weapon)
   /* .then(()=>
    Swal.fire({
      title: 'Processing transaction!',
      timer: 1500,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getStakedAssets())
    .then(()=>getSignupVerification())*/
  }
 }

 async function jetpackAssignProcess(to_start,to_go,to_use_weapon,to_start_go){
  if(to_start.length>0 ){
    await bulkMissionStart(ual,to_start.map((item)=>{return item.asset_id}))
    .then(()=>bulkMissionGoWeapon(ual,to_start_go))
  }
  if(to_go.length>0 ){
    await bulkMissionGoWeapon(ual,to_go)
  }
  
  if(to_use_weapon.length>0 ){
    await bulkMissionGoWeapon(ual,to_use_weapon)
  }
 }

 function openChooseStartingMission(){
  if (userData.stakedChickens.find((asset)=>asset.template.template_id == "618025" || asset.template.template_id == "618026" || asset.template.template_id == "618027")){
  Swal.fire({
    showCancelButton: true,
    cancelButtonText: `Jetpack`,
    confirmButtonText: 'Default',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      openMassAssignModal()
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      chooseJetpackModal()
    }
  })
}
else {
 openMassAssignModal()
}
 }

  async function openMassAssignModal() {
    let _selectedMission ={}

    /*Total cost: {Number(selected.length*mission.mission_cost[0].quantity.split(" ")[0]).toFixed(8)+" GOLD"} {mission.mission_cost.length>1&&"+ "+selected.length*mission.mission_cost[1].quantity.split(" ")[0]+" WAX"} */
    if (selectedChickens.length>0){
    if (chickensOnMission.length+selectedChickens.filter((asset)=>chickensOnMission.find((chick)=>chick.asset_id===asset.asset_id)===undefined).length>userData.maxSlots){
      Swal.fire({
        icon: "error",
        title:"Limit exceeded",
        text: "The number of missions cannot exceed "+userData.maxSlots,
      })
    }
    else {
      selectChicken([])
        let missionArray = userData.missionsList.filter((mission)=>mission.allowlist[0][0].level_req === Number(1) && String(mission.allowlist[0][0].template_id)==="583633")
        SelectorModal.fire({
          title: 'Choose mission!',
          width:"600px",
          html:(<MissionSelectModal onValue={value => { _selectedMission = value }} array={missionArray}/>),
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: false,

        })
        .then((result)=>{
          if (result.isConfirmed){
            let _Eggs = 0;
            let _Wax = 0;
            let _missionsCost = selectedChickens.map((chicken)=> {return {
              mission_cost:userData.missionsList.find((mission)=>String(mission.allowlist[0][0].template_id)===chicken.template.template_id && mission.allowlist[0][0].level_req === chicken.data.level && mission.info[0].value ===_selectedMission.info[0].value).mission_cost} })
              let _totalEgg = _missionsCost.map((costRow)=>{
                return costRow.mission_cost.find((item)=>item.contract==="dcyctokeneos").quantity.split(" ")[0]})
              let _totalWax = _missionsCost.map((costRow)=>{
                return costRow.mission_cost.find((item)=>item.contract==="eosio.token")?costRow.mission_cost.find((item)=>item.contract==="eosio.token").quantity.split(" ")[0]:0})
              _Eggs = _totalEgg.reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
              _Wax = _totalWax.reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
              console.log(_Eggs.toFixed(8),_Wax.toFixed(8))
            Swal.fire({
              title:"Confirmation",
              text:`are you sure you want to start missions, total cost:${_Eggs.toFixed(8)} GOLD + ${_Wax.toFixed(8)} WAX`,
              icon:"question",
              preConfirm: ()=>{
                let to_start = selectedChickens.filter((selected)=>selected.associatedMission===undefined)
                console.log(to_start)
                let to_start_go = to_start.map((chicken)=> {return {asset_id:chicken.asset_id,
                  mission_id:userData.missionsList.find((mission)=>String(mission.allowlist[0][0].template_id)===chicken.template.template_id && mission.allowlist[0][0].level_req === chicken.data.level && mission.info[0].value ===_selectedMission.info[0].value).mission_id} })
                let filtered_to_use = selectedChickens.filter((selected)=>selected.associatedMission!==undefined && selected.associatedMission.asset_ids.length>1)
                let to_use_weapon = filtered_to_use.map((chicken)=> {return {
                  //weapon_nft:chicken.associatedMission.asset_ids[1],
                  //user_mission_id:chicken.asset_id,
                  asset_id:chicken.asset_id,
                  mission_id:userData.missionsList.find((mission)=>chicken.associatedMission!==undefined && chicken.associatedMission.asset_ids.length>1 && mission.allowlist[1]!==undefined && mission.allowlist[1][0]!==undefined && String(mission.allowlist[1][0].template_id)=== String(userData.stakedChickens.find(weapon=>weapon.asset_id === chicken.associatedMission.asset_ids[1]).template.template_id) && mission.allowlist[1][0].level_req === userData.stakedChickens.find(weapon=>weapon.asset_id === chicken.associatedMission.asset_ids[1]).data.level && mission.allowlist[0][0].level_req === chicken.data.level && String(mission.allowlist[0][0].template_id)===chicken.template.template_id /*&& mission.info[0].value ===_selectedMission.info[0].value*/).mission_id} })
                let filtered_to_go = selectedChickens.filter((selected)=>selected.associatedMission!==undefined && selected.associatedMission.asset_ids.length===1)
                  let to_go = filtered_to_go.map((chicken)=> {return {asset_id:chicken.asset_id,
                  mission_id:userData.missionsList.find((mission)=>String(mission.allowlist[0][0].template_id)===chicken.template.template_id && mission.allowlist[0][0].level_req === chicken.data.level && mission.info[0].value ===_selectedMission.info[0].value).mission_id} })
                  console.log("start:",to_start,"go:",to_go,"use:",to_use_weapon)
                  massAssignProcess(to_start,to_go,to_use_weapon,to_start_go).then(()=>
                  Swal.fire({
                    title: 'Processing transaction!',
                    timer: 5000,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    timerProgressBar: false,
                    didOpen: () => {
                      Swal.showLoading()
                    },
                  })
                  .then(()=>getStakedAssets())
                  .then(()=>getSignupVerification())
                  )
                 
              }
            })
          }
        })
      }
    }
    else {
      Swal.fire({
        icon: "error",
        title:"Select asset",
        text: "Select at least 1 Chicken",
      })
    }

  }

  function selectorHandler(asset){
    //selectChicken([...selectedChickens,asset])
    if ((asset.associatedMission===undefined && (asset.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(asset.availability.available_at+"Z").getTime()/1000))) || 
    (asset.associatedMission!==undefined && asset.associatedMission.completed_missions.length<8 && Math.round(new Date().getTime()/1000)>Math.round(new Date(asset.associatedMission.available_at+"Z").getTime()/1000))
    ){
    selectedChickens.find((chicken)=>chicken.asset_id===asset.asset_id)?selectChicken(selectedChickens.filter((chicken)=>chicken.asset_id!==asset.asset_id)):selectChicken([...selectedChickens,asset])
    }
    
  }

  function bulkCollectTaxes(){
    let chickensToCollect = stakedChickens.filter((chicken)=>Math.round(new Date().getTime()/1000)>Math.round(new Date(chicken.availability.available_at+"Z").getTime()/1000)).map((asset)=>asset.asset_id)
    bulkCollectTax(ual,chickensToCollect).then(()=>getSignupVerification()).then(()=>getStakedAssets())
  }

  function collectRewardHandler(){
    let chickensWithMissions = userData.stakedChickens.filter((asset)=>asset.associatedMission!==undefined)
    let availableToClaim = chickensWithMissions.filter((asset)=>
      asset.associatedMission.completed_missions.length>=8 
        && Math.round(new Date().getTime()/1000)>Math.round(new Date(asset.associatedMission.available_at+"Z").getTime()/1000)
        && userData.unclaimedRewards.find((row)=>row.user_mission_id === asset.associatedMission.index)===undefined
    ).map(asset=>asset.associatedMission.index)
    if(userData.unclaimedRewards.length>0|| availableToClaim.length>0){
      if(availableToClaim.length>0){
        let arr =[]
        if(userData.unclaimedRewards.length>0){
          arr = userData.unclaimedRewards.map((row)=>{return row.user_mission_id})
        }
        let toCollect = arr.concat(availableToClaim)
        bulkMissionComplete(ual,availableToClaim)
        .then(()=>
        Swal.fire({
          title: 'Generating reward!',
          timer: 2000,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
          },
        })
        )
        .then(()=>bulkMissionCollect(ual,toCollect))
        .then(()=>getSignupVerification())
        .then(()=>getStakedAssets())
        .then(()=>getUnclaimedRewards())
      }
      else{
        let mappedArr = userData.unclaimedRewards.map((row)=>{return row.user_mission_id})
        bulkMissionCollect(ual,mappedArr)
        .then(()=>getSignupVerification())
        .then(()=>getStakedAssets())
        .then(()=>getUnclaimedRewards())
      }
    }
  }

  function update(){
    addCompleteCountdown((countdown)=>countdown+1)
  }

  function selectAll(){
    //selectChicken([...selectedChickens,asset])
    let assets = userData.stakedChickens.filter(asset=>CHICKEN_TEMPLATES.find(chicken=>asset.template.template_id===chicken) )
    assets.forEach((asset)=>{
      if ((asset.isWeapon===false)&&(asset.associatedMission===undefined && (asset.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(asset.availability.available_at+"Z").getTime()/1000))) || 
    (asset.associatedMission!==undefined && asset.associatedMission.completed_missions.length<8 && Math.round(new Date().getTime()/1000)>Math.round(new Date(asset.associatedMission.available_at+"Z").getTime()/1000))
    ){
      selectedChickens.length!==0?selectChicken([]):selectChicken((selected)=>[...selected,asset])
    }
  }
    )
    console.log(selectedChickens)
  }

  //onClick={()=>navigate(`/asset/${item.asset_id}`)}
  //<button onClick={()=>item.availability!==undefined&&item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)?collectEggs(item.asset_id):showTaxError()} className={["fa-solid","btn2","fa-egg",item.availability!==undefined&&item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)?"available-to-steal":""].join(" ")}></button>
    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col text-center mb-2">
            <div className="btn-group" role="group">
              <div onClick={()=>/*openMassAssignModal()*/openChooseStartingMission()} className="btn btn-primary">mass assign</div>
              <div onClick={()=>selectAll()} className="btn btn-primary">select all</div>
            </div>
            </div>
            <div className="col text-center mb-2">
            <div className="btn-group" role="group">
              {(userData.unclaimedRewards.length>0||userData.stakedChickens.find((chicken)=>chicken.associatedMission!==undefined && (chicken.associatedMission.completed_missions.length>=8 
          && Math.round(new Date().getTime()/1000)>Math.round(new Date(chicken.associatedMission.available_at+"Z").getTime()/1000))))&&
                <div onClick={()=>collectRewardHandler()} className="btn btn-primary">Collect rewards</div>
              }
              {
                stakedChickens.find((chicken)=>Math.round(new Date().getTime()/1000)>Math.round(new Date(chicken.availability.available_at+"Z").getTime()/1000)) &&
              (<div onClick={()=>bulkCollectTaxes()}  className="btn btn-primary">Collect Taxes</div>)
              }
            </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-center mb-2">
              <div className="div">Active missions:{chickensOnMission.length+selectedChickens.filter((asset)=>chickensOnMission.find((chick)=>chick.asset_id===asset.asset_id)===undefined).length}/{userData.maxSlots}</div> 
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="stake_mb">
                    <div className="picture_nft">
                      <img className="img-fluid" alt='add-nft' src="image/workspace/add_NFT.png"/>
                    </div>
                    <div className="content_card text-center mt-3">
                      <h4>
                        <a className="text-add">Add NFT</a>
                      </h4>
                      <div works="" className="buttons">
                        <div works="" onClick={()=>openStakeModal()} className="common-button btn6 button-primary button-38">Stake</div>
                        <div works="" onClick={()=>openUnstakeModal()} className="common-button btn5 button-38 outline">Unstake</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            { currentTableData.map(item=>{
              return (
                <div key={item.asset_id} className="col-xl-3 col-lg-6 col-sm-6">
              <div className="card">
                <div className={["card-body",selectedChickens.find((asset)=>asset.asset_id === item.asset_id)?"selected":""].join(" ")}>
                  <div className="stake_mb">
                    <div className="dcactives">
                      <div className="picture_nft d-flex justify-content-end">
                        <div title='level' style={{"position":"absolute","color":"white"}} className="d-flex"><span className='fs-2'>{item.data.level!==undefined?item.data.level:0}</span></div>
                        <img onClick={()=>selectorHandler(item)} className="img-fluid" alt='nft image' src={"https://"+userData.ipfs+"/ipfs/"+item.data.img}/>
                        <div className="multi-box">
                        <button title='open missions page' onClick={()=>navigate(`/asset/${item.asset_id}`)} className={["fa-solid","btn2","fa-compass",(item.associatedMission!==undefined&&(item.associatedMission.completed_missions.length<8&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.associatedMission.available_at+"Z").getTime()/1000)))&&"available-to-continue",(item.associatedMission!==undefined&&(item.associatedMission.completed_missions.length>=8&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.associatedMission.available_at+"Z").getTime()/1000)))&&"available-to-steal"].join(" ")}></button>
                          <button title="upgrade" onClick={()=>((item.data.level === undefined)||(item.data.level!==undefined&&item.data.level<200))?openLvlModal(item.asset_id,item.data.level||0,item):showlvlupError()} className={["fa-solid","btn3","fa-hand-back-fist",(item.availability.is_busy===0&&item.availability.is_queued===0&&item.data.level_queue&&item.data.upgrade_available_at>0&&item.data.level_queue.length>=0&&Math.round(new Date().getTime()/1000)>item.data.upgrade_available_at)?"available-to-steal":""].join(" ")}></button>
                          <button title="collect tax" onClick={()=>eliteClaim(item.template.template_id,item.asset_id)} className="fa-solid btn4 fa-egg"></button>
                        </div>
                        <div className="multi-box" style={{"marginLeft":"52px"}}>
                          <button title="equip weapon" onClick={()=>(item.associatedMission!==undefined && item.associatedMission.asset_ids.length>1 && item.associatedMission.completed_missions.length<1)?unequipModal(item.asset_id):openEquipModal(item)} className="fa-solid btn4 fa-ring"></button>
                        </div>
                      </div>

                        {/*lvl up countdown*/(item.availability!==undefined&&item.data.upgrade_available_at!==undefined&&item.availability.is_busy===0&&item.availability.is_queued===0&&item.data.level_queue!==undefined&&item.data.level_queue.length>0&&Math.round(new Date().getTime()/1000)<=item.data.upgrade_available_at)&&
                        <div style={{"position":"relative","bottom":"35px"}} className="countdown">
                      <Countdown icon={"bolt"} callback={update} sec={(item.data.upgrade_available_at-Math.round(new Date().getTime()/1000)<=0)?0:item.data.upgrade_available_at-Math.round(new Date().getTime()/1000)}/>
                      </div>
                        }

                        {/*claim tax countdown*/(item.availability!==undefined&&((item.data.level_queue===undefined)||(item.data.level_queue!==undefined&&item.data.level_queue.length<=0 && item.data.upgrade_available_at<=0))&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000))&&
                        <div style={{"position":"relative","bottom":"35px"}} className="">
                        <Countdown icon={"snowflake"} callback={update} sec={Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)}/>
                        </div>
                          }

                      <Countdown callback={update} sec={item.associatedMission===undefined?0:Math.round(new Date(item.associatedMission.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(item.associatedMission.available_at+"Z").getTime()/1000-new Date().getTime()/1000)}/>
                    </div>
                    <div className="content_card text-center mt-3">
                      <div className="item-bottom">
                        <div className="value justify-content-center">
                          <div className="value-text fs-5">{item.name!==undefined?item.name.split(" | ")[0]:"Chicken"}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              )

            })}
            
          </div>
        </div>

      </div>
    );
  }
  export default WorkspacePage;