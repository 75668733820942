import './App.css';
/*import './styles/roost.vp.css'
import './styles/hakkv_.css'
import './styles/fontawesome.css'*/
import { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import SideBar from './components/SideBar/SideBar';
import InventoryPage from './components/InventoryPage/InventoryPage';
import MarketPage from './components/MarketPage/MarketPage';
import FoxesPage from './components/FoxesPage/FoxesPage';
import WorkspacePage from './components/WorkspacePage/WorkspacePage';
import Footer from './components/Footer/Footer';
import LoginPage from './components/LoginPage/LoginPage'; //login page component
import ExchangePage from './components/ExchangePage/ExchangePage';
import AccountPage from './components/AccountPage/AccountPage';
import RefPage from './components/AccountPage/RefPage';
import { UALContext, UALProvider } from 'ual-reactjs-renderer';
import { GlobalStateContext } from './components/Contexts/stateContext';
import { signup } from './components/controllers/missionsController';
import Swal from 'sweetalert2';
import AssetPage from './components/WorkspacePage/AssetPage';
import Leaderboard from './components/Leaderboard/Leaderboard';
import AlphaWL from './components/Leaderboard/AlphaWL';
import Maintenance from './components/Maintenance/Maintenance';
import Weaponry from './components/Weaponry/Weaponry';
import KittyPage from './components/KittyPage/KittyPage';
import Craft from './components/Weaponry/Craft';



function LayoutsWithNavbar() {
  const [sideBarIsOpen,setSideBar]= useState(true)
  const {userData:{isSignedUp}} = useContext(GlobalStateContext)
  const ual = useContext(UALContext)
  
  const updateState = () =>{
    setSideBar(!sideBarIsOpen)
  }

   useEffect(()=>{
    if(isSignedUp===false){
      const referrer = localStorage.getItem("referrer")
     Swal.fire({
      title: 'Please sign up!',
      input: 'text',
      inputLabel: 'Referrer (Optional)',
      allowOutsideClick: false,
      inputValue: referrer||"",
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        let ref;
        input===""?ref=".":ref=input; // if ref="." == no ref
        return signup(ual,ref).catch((error) => {
          Swal.showValidationMessage(
            error)
        })
      }
    })}
    console.log(isSignedUp)
   },[isSignedUp])
  return (
    <>
      <div data-sidebar="hakkv" data-layout="verty">
      <div id="main-wrapper" className={sideBarIsOpen?"":"menu-toggle"}>
      <NavBar callback={updateState} sidebar={sideBarIsOpen}/>
      <SideBar/>
      <Outlet /> 
      
      <Footer/>
      </div>
      </div>
    </>
  );
}

function App() {
  const [isLoggedIn,setLoggedIn] = useState(false)
  const ual = useContext(UALContext)
  const {userData} = useContext(GlobalStateContext)
  return (
    <div className="App">
      

      <Routes>
      <Route path="/" element={ual.activeUser?<LayoutsWithNavbar />:<Navigate to='/auth'/>}>
          <Route path="/" element={userData.maintenance === false?<WorkspacePage />:<Navigate to="/maintenance"/>} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="market" element={<MarketPage />} />
          <Route path="foxes" element={<FoxesPage />} />
          <Route path="inventory" element={<InventoryPage />} />
          <Route path="workspace" element={<WorkspacePage />} />
          <Route path="exchange" element={<ExchangePage/>}/>
          <Route path="account" element={<AccountPage/>}/>
          <Route path="leaderboard" element ={<Leaderboard/>}/>
          <Route path="alphawl" element ={<AlphaWL/>}/>
          <Route path="maintenance" element ={<Maintenance/>}/>
          <Route path="weaponry" element={<Weaponry/>}/>
          <Route path="kitty" element={<KittyPage/>}/>
          <Route path='craft' element={<Craft/>}/>
          
          <Route path="asset/:assetId" element={<AssetPage/>}/>
      </Route>
      <Route path="auth" element={<LoginPage />}/>
      <Route path="ref/:ref" element={<RefPage/>}/>
      </Routes>

    </div>
  );
}

export default App;
