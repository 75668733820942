import {ActionBuilder} from './ActionBuilder';
import Swal from 'sweetalert2'
let ATOMICASSETS_TRANSFER_ACTION = new ActionBuilder().setActionAccount("atomicassets").setActionName("transfer")
//const ACTIVE_PERMISSION = [{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}]
let CLAIM_ACTION = new ActionBuilder().setActionAccount("dcycstealing").setActionName("claim")
let CLAIM_STOLENNFT = new ActionBuilder().setActionAccount("dcycstealing").setActionName("claimstolen")
const CONTRACT_NAME = "dcycstealing"
const CLAIM = "claim"
const EGG_CONTRACT = "dcyctokeneos"
const WAX_CONTRACT = "eosio.token"
const TRANSFER_ACTION = "transfer"
const DCYC_MISSIONS_CONTRACT = "dcycmissions"
const WAX_SYMBOL = "WAX"
const EGG_SYMBOL = "EGG"
const UNPACK_CONTRACT = "dcycofficiam"
const ATOMICASSETS_CONTRACT = "atomicassets"
/*
 Transaction structure
const demoTransaction = {
    actions: [{
      account: 'eosio.token',
      name: 'transfer',
      authorization: [{
        actor: 'imonlytestin', // use account that was logged in
        permission: 'active',
      }],
      data: {
        from: 'imonlytestin', // use account that was logged in
        to: 'eosio.token',
        quantity: '1.00000000 WAX',
        memo: 'testing',
      },
    }],
  }*/
//CLAIM_ACTION.setData({owner:"imonlytestin",roll:asset_id}).build()

export async function claimBulk(ual,rolls) {
  let assets = []
  try {
    rolls.forEach((asset) => assets.push(
      new ActionBuilder().setActionAccount(CONTRACT_NAME)
      .setActionName(CLAIM)
      .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
      .setData({owner:ual.activeUser.accountName,roll:asset})
      .build()
    ))
    let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
    console.log(transaction)
    console.log(transaction.transaction.processed.action_traces)

  } catch (error) {
    console.error(error)
    Swal.fire("error", error.message.toString(), "error");
    throw new Error(error)
  }
  
}


export async function unpackMemo(ual,asset_id,memo) {
  /*from: 'imonlytestin', // use account that was logged in
      to: 'eosio.token',
      asset_ids[],
      memo: 'testing',*/
  try {
    let transaction = await ual.activeUser.signTransaction({actions:[
      new ActionBuilder().setActionAccount(ATOMICASSETS_CONTRACT)
      .setActionName(TRANSFER_ACTION)
      .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
      .setData({from:ual.activeUser.accountName,to:UNPACK_CONTRACT,asset_ids:[asset_id],memo})
      .build()
    ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
    console.log(transaction)
    console.log(transaction.transaction.processed.action_traces)

  } catch (error) {
    console.error(error)
    Swal.fire("error", error.message.toString(), "error");
    throw new Error(error)
  }
  
}


  export async function transferAsset(ual,asset_id,blend_id) {
    
    try {
      await ual.activeUser.signTransaction({actions:[ATOMICASSETS_TRANSFER_ACTION.setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}]).setData({from:ual.activeUser.accountName,to:"dcycstealing",asset_ids:[asset_id],memo:"blend:"+blend_id}).build()]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      await Swal.fire({
        title: 'Processing transaction!',
        timer: 2500,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      let transaction= await ual.activeUser.signTransaction({actions:[CLAIM_ACTION.setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}]).setData({owner:ual.activeUser.accountName,roll:asset_id}).build()]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log("sent")
      console.log(transaction.transaction.processed.action_traces)
      let inline_traces =await transaction.transaction.processed.action_traces[0].inline_traces
      let asset_saved = await inline_traces[inline_traces.length-1].act.data.skipped_steal
      //2.5sec delay
      await Swal.fire({
        title: 'Processing transaction!',
        timer: 4000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      //asset was stolen
      if (asset_saved == 0){
        Swal.mixin({
          toast: !0,
          position: "bottom-start",
          showConfirmButton: !1,
          timer: 5000,
          timerProgressBar: !0,
          didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer)
              toast.addEventListener("mouseleave", Swal.resumeTimer)
          }
      }).fire({
          icon: "warning",
          title: "oops looks like your asset was stolen"
      })
      }
      //asset was saved
      if (asset_saved == 1){
        Swal.mixin({
          toast: !0,
          position: "bottom-start",
          showConfirmButton: !1,
          timer: 5000,
          timerProgressBar: !0,
          didOpen: e => {
              e.addEventListener("mouseenter", Swal.stopTimer)
              e.addEventListener("mouseleave", Swal.resumeTimer)
          }
      }).fire({
          icon: "success",
          title: "all assets are safe this time"
      })
      }

    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }


  export async function transferNeftyBlocks(ual,asset_ids,blend_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(ATOMICASSETS_CONTRACT)
        .setActionName("transfer")
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({asset_ids,from:ual.activeUser.accountName,to:"blend.nefty",memo:`blend:${blend_id}`})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
      console.log("sent")
      console.log(transaction.transaction.processed.action_traces)
      let timestamp = new Date().toISOString()
      //let inline_traces =await transaction.transaction.processed.action_traces[0].inline_traces
      //let asset_saved = await inline_traces[inline_traces.length-1].act.data.skipped_steal
      //2.5sec delay
      await Swal.fire({
        title: 'Processing transaction!',
        timer: 4000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      let history_url = `https://api.waxsweden.org:443/v2/history/get_actions?account=${ual.activeUser.accountName}&filter=atomicassets%3Alogmint&sort=desc&after=${timestamp}&simple=true&noBinary=true`
      let result = null
      let jsoned = await fetch(history_url).then(response=>response.json()).then((data)=>result = data)
      let asset_saved = await result.total.value>0?1:0
      //asset was stolen
      if (asset_saved == 0){
        Swal.mixin({
          toast: !0,
          position: "bottom-start",
          showConfirmButton: !1,
          timer: 5000,
          timerProgressBar: !0,
          didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer)
              toast.addEventListener("mouseleave", Swal.resumeTimer)
          }
      }).fire({
          icon: "warning",
          title: "bomb exploded while crafting"
      })
      }
      //asset was saved
      if (asset_saved == 1){
        Swal.mixin({
          toast: !0,
          position: "bottom-start",
          showConfirmButton: !1,
          timer: 5000,
          timerProgressBar: !0,
          didOpen: e => {
              e.addEventListener("mouseenter", Swal.stopTimer)
              e.addEventListener("mouseleave", Swal.resumeTimer)
          }
      }).fire({
          icon: "success",
          title: "all assets are safe this time"
      })
      }

    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }


  export async function stakeAssets(ual,asset_ids) {
    
    try {
      await ual.activeUser.signTransaction({actions:[ATOMICASSETS_TRANSFER_ACTION.setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}]).setData({from:ual.activeUser.accountName,to:"dcycmissions",asset_ids,memo:"stake"}).build()]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log("sent")
      //2.5sec delay
      await Swal.fire({
        title: 'Processing transaction!',
        timer: 3500,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

// index = index of nft to steal 
  export async function claimstolenAction(ual,index,foxnft) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[CLAIM_STOLENNFT.setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}]).setData({owner:ual.activeUser.accountName,pool_name:"dcycthiefnft",index:index,nft:foxnft}).build()]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      //console.log(transaction)
      //console.log(transaction.transaction.processed.action_traces)
      //2.5sec delay
      await Swal.fire({
        title: 'Processing transaction!',
        timer: 3500,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      await Swal.fire(
        'Steal',
        'Successful steal!',
        'success'
      )
      

    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

export async function transferWax(ual,amount) {
    /*from: 'imonlytestin', // use account that was logged in
        to: 'eosio.token',
        quantity: '1.00000000 WAX',
        memo: 'testing',*/
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(WAX_CONTRACT)
        .setActionName(TRANSFER_ACTION)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({from:ual.activeUser.accountName,to:DCYC_MISSIONS_CONTRACT,quantity:amount+" "+WAX_SYMBOL,memo:"deposit"})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)

    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  export async function transferEgg(ual,amount) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(EGG_CONTRACT)
        .setActionName(TRANSFER_ACTION)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({from:ual.activeUser.accountName,to:DCYC_MISSIONS_CONTRACT,quantity:amount+" "+EGG_SYMBOL,memo:"deposit"})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction.transaction.processed.action_traces)

    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }