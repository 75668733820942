import '../../styles/inventory/backpack.css'
import Pagination from '../Pagination/Pagination';
import { GlobalStateContext } from '../Contexts/stateContext';
import { claimstolenAction } from '../controllers/sendController';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { stakeAssets } from '../controllers/sendController';
import { useHorizontalScroll } from '../Hooks/useSideScroll';
import { bulkUnstake, bulkCollectTax, collectTax, queueUnstake, cancelUnstake } from '../controllers/missionsController';
import Countdown from '../Countdown/Countdown';
import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
const FOX_TEMPLATES=["602317","602320","602321","602324","602327","602328","602331","602332","602333","602335",//class 1 foxes
"611266","611265","611264","611263","611261","611260","611259","611258","611255","611253",//class 2 foxes
"620602","620603","620604","620605","620606",//class 3 foxes
"638625","638626","638627","638629","638630",//class 4 foxes
"645474","645475","645476","645477","645478"//class 5 foxes
]

const SweetalertModal = ({onValue,array,callback,ipfs}) =>{
  const [selected,setSelection] = useState([])
  useEffect(() => { onValue(selected) }, [selected])
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array.filter(item=> FOX_TEMPLATES.find((chicken)=>item.template.template_id===chicken) )
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center">
                  <div onClick={()=>{selected.includes(item.asset_id)?setSelection(selected.filter((element)=>element!==item.asset_id)):setSelection([...selected,item.asset_id])}} className={["nft-container",selected.includes(item.asset_id)?"selected":""].join(" ")}>
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  </div>
                  <span>#{item.asset_id}</span>
              </div>)
              })
              }
          </div>
          </div>
  );
}

const UnstakeModal = ({onValue,array,callback,ual,refreshFunction,ipfs}) =>{
  const [selected,setSelection] = useState([])
  //console.log(userData.ipfs)
  useEffect(() => { onValue(selected) }, [selected])
  function update(){console.log("timer update")}
  function cancel(asset_id){
    cancelUnstake(ual,asset_id)
    .then(()=>Swal.fire({
      position: "center",
      icon: "success",
      title: "Processing transaction!",
      showConfirmButton: !1,
      timer: 2500
  }))
    .then(()=>refreshFunction())
  }
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array.filter(item=>FOX_TEMPLATES.find((chicken)=>item.template.template_id===chicken) && item.availability.is_busy === 0)
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center">
                  <div style={{"position":"relative"}} onClick={()=>{selected.find((asset)=>asset.asset_id === item.asset_id)?setSelection(selected.filter((element)=>element.asset_id!==item.asset_id)):setSelection([...selected,{asset_id:item.asset_id,availability:item.availability}])}} className={["nft-container",selected.find((asset)=>asset.asset_id === item.asset_id)?"selected":""].join(" ")}>
                    {item.availability.is_queued===1 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000) &&
                    <i style={{"position":"absolute","fontSize":"35px","color":"darkgreen"}} className="fa-solid fa-check">
                    </i>
                    }
                    {item.availability.is_queued===1 && Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000) &&
                    <i style={{"position":"absolute","fontSize":"35px","color":"sandybrown"}} className="fa-solid fa-angles-left">
                    </i>
                    }
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  
                  </div>
                  <span>#{item.asset_id}</span>
                  <Countdown callback={update} sec={Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)}/>
                  {item.availability.is_queued===1&&<div onClick={()=>cancel(item.asset_id)} className="btn btn-primary">Cancel queue</div>}
              </div>)
              })
              }
          </div>
          </div>
  );
}

let PageSize = 7;
function FoxesPage() {
  const {userData,getInventory,getStakedAssets,getSignupVerification,getAvailableToSteal} = useContext(GlobalStateContext)
  const ual = useContext(UALContext)
  const SelectorModal = withReactContent(Swal)
  let selectedFromModal;

  const [completeCountdowns,addCompleteCountdown] = useState(0)
  const [currentTime,updateCurrentTime] = useState(new Date().getTime()/1000)
  useEffect(()=>{
    updateCurrentTime(new Date().getTime()/1000)
  },[completeCountdowns])

  const [currentPage, setCurrentPage] = useState(1);
  const [stakedFoxes,setStakedFoxes] = useState([])
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return userData.stakedChickens.filter(asset=>FOX_TEMPLATES.find(fox=>asset.template.template_id===fox) ).slice(firstPageIndex, lastPageIndex);
  }, [currentPage,userData.stakedChickens]);
  useEffect(()=>{
    setStakedFoxes(userData.stakedChickens.filter(asset=>FOX_TEMPLATES.find(fox=>asset.template.template_id===fox) ))
  },[userData.stakedChickens])
  function initStealAction(fox_weight_start,fox_weight_end,foxnft){
    let found = userData.stolennfts.find(({start_weight,end_weight})=> start_weight>=fox_weight_start && end_weight<=fox_weight_end)
    if (found){
      claimstolenAction(ual,found.index,foxnft).then(()=>getInventory()).then(()=>getAvailableToSteal())
    }
    else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Nothing to steal",
        showConfirmButton: !1,
        timer: 1500
    })
    }
  }
  let showTaxError = () =>{
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Claim is not ready",
      showConfirmButton: !1,
      timer: 1500
  })
  }
  let collectEggs = (asset_id) =>{
    collectTax(ual,asset_id).then(()=>getSignupVerification()).then(()=>getStakedAssets())
  }

  let openUnstakeModal = () =>{
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<UnstakeModal ipfs={userData.ipfs} ual={ual} refreshFunction={getStakedAssets} onValue={value => { selectedFromModal = value }} array={userData.stakedChickens}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal.length>0){
        let to_unstake = selectedFromModal.filter((item)=>(item.availability.is_queued === 1 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)))
        let to_queue = selectedFromModal.filter((item)=>(item.availability.is_queued === 0 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)))
        console.log("queue",to_queue)
        console.log("unstake",to_unstake)
        if(to_queue.length>0){
          let assets = to_queue.map((item)=>item.asset_id)
          queueUnstake(ual,assets)
          .then(()=>Swal.fire({
            position: "center",
            icon: "success",
            title: "Assets sent to unstake queue",
            showConfirmButton: !1,
            timer: 1500
        }))
          .then(()=>getStakedAssets())
        }
        if(to_unstake.length>0){
          let assets = to_unstake.map((item)=>item.asset_id)
        bulkUnstake(ual,assets)
        .then(()=>getStakedAssets())
        .then(()=>getInventory())
        }
        
      }
      }
    })
  }

  function bulkCollectTaxes(){
    let foxesToCollect = stakedFoxes.filter((fox)=>Math.round(new Date().getTime()/1000)>Math.round(new Date(fox.availability.available_at+"Z").getTime()/1000)).map((asset)=>asset.asset_id)
    bulkCollectTax(ual,foxesToCollect).then(()=>getSignupVerification()).then(()=>getStakedAssets())
  }

  let openStakeModal = () => {
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<SweetalertModal ipfs={userData.ipfs} onValue={value => { selectedFromModal = value }} array={userData.inventory}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal.length>0){
        stakeAssets(ual,selectedFromModal)
        .then(()=>getStakedAssets())
        .then(()=>getInventory())}
      }
    })
  }


  function update(){
    addCompleteCountdown((countdown)=>countdown+1)
  }

    return (
        <div className="content-body">
        <div className="container-fluid">
        <div className="row">
            <div className="col text-center mb-2">
            </div>
            <div className="col text-center mb-2">
            {stakedFoxes.find((fox)=>Math.round(new Date().getTime()/1000)>Math.round(new Date(fox.availability.available_at+"Z").getTime()/1000)) &&(
              <div className="btn-group" role="group">
              <div onClick={()=>bulkCollectTaxes()}  className="btn btn-primary">Collect Taxes</div>
            </div>)
            }
            </div>
          </div>
          
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="stake_mb">
                    <div className="picture_nft">
                      <img className="img-fluid" alt='add-nft' src="image/workspace/add_NFT.png"/>
                    </div>
                    <div className="content_card text-center mt-3">
                      <h4>
                        <div className="text-add">Add NFT</div>
                      </h4>
                      <div works="" className="buttons">
                        <div onClick={()=>openStakeModal()}  works="" className="common-button btn6 button-primary button-38">Stake</div>
                        <div onClick={()=>openUnstakeModal()}  works="" className="common-button btn5 button-38 outline">Unstake</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {currentTableData.map(item => {
                          return (
                            <div key={item.asset_id} className="col-xl-3 col-lg-6 col-sm-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="stake_mb">
                                  <div className="dcactives">
                                    <div className="picture_nft">
                                      <img className="img-fluid" referrerPolicy='no-referrer' alt='fox_nft' src={"https://"+userData.ipfs+"/ipfs/"+item.data.img}/>
                                      <div className="multi-box">
                                      <button onClick={()=>item.availability!==undefined&&item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)?collectEggs(item.asset_id):showTaxError()} className={["fa-solid","btn2","fa-egg",item.availability!==undefined&&item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)?"available-to-steal":""].join(" ")}></button>
                                        <button onClick={()=>initStealAction(item.data.weight_start,item.data.weight_end,item.asset_id)} className={["fa-solid","fa-box-open",userData.stolennfts.some(({start_weight,end_weight})=> start_weight>=item.data.weight_start && end_weight<=item.data.weight_end)?"available-to-steal":""].join(" ")}></button>
                                        
                                      </div>
                                    </div>
                                    <Countdown callback ={update}  sec={Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)} />
                                  </div>
                                  <div className="content_card text-center mt-3">
                                    <div className="item-bottom">
                                      <div className="value justify-content-center">
                                        <div className="value-text fs-5">{item.name!==undefined?item.name.split(" | ")[0]:"Fox"}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                                );
                            })}
          </div>
        </div>
        <Pagination
                    currentPage={currentPage}
                    totalCount={stakedFoxes.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                    />
      </div>
    );
  }
  export default FoxesPage;