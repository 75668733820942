import { useContext, useEffect,useState } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { useNavigate } from 'react-router-dom';
import '../../styles/login/connect.css'
function LoginPage() {
  const ual = useContext(UALContext)
  let navigate = useNavigate()
  
  //endpoints list
  const endpoints = [
    "wax.greymass.com",
    "api.waxsweden.org",
    "api.wax.alohaeos.com",
    "wax.eoseoul.io",
    "api-wax-mainnet.wecan.dev",
    "wax.api.eosnation.io",
    "wax.cryptolions.io"
  ]
  const [selectedRpcEndpoint,setRpcEndpoint] = useState(localStorage.getItem("rpc")||endpoints[0])

  useEffect(()=>{
    if (ual.activeUser !== null){navigate('/')}
  },[ual.activeUser])
  const handleChange = (event) =>{
    setRpcEndpoint(event.target.value)
    window.location.reload()
    console.log("reload")
    //localStorage.setItem("rpc",selectedRpcEndpoint)
  }
  useEffect(()=>{
    localStorage.setItem("rpc",selectedRpcEndpoint)
  },[selectedRpcEndpoint])
    return (
        <div className='sarmx login'>
        <div className="access yckh69">
        <div className="container yckh69">
          <div className="row justify-content-center yckh69 align-items-center">
            <div className="col-md-6">
              <div className="access-content">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="auth-login">
                      <div className="text-center mb-3">
                        <img alt='dcyc_logo' src="image/logo/dc_your_logo.png"/>
                      </div>
                      <h4 className="text-center mb-4">Welcome back, player!</h4>
                      <div className="server_op">
                        <select className={"rpc"} value={selectedRpcEndpoint} onChange={handleChange} >
                          {endpoints.map(endpoint=>(
                          <option key={endpoint} value={endpoint}>{endpoint}</option>
                          ))}
                          
                        </select>
                      </div>
                      <div className="text-center">
                        <a onClick={()=>{ual.showModal()}}>
                          <button className="button-lg"><i className="chain-end-icon">
                              <img alt='waxp' src="image/login/waxp.svg"/>
                            </i>Connect wallet 
                            <i className="chain-end-icon">
                              <img alt='anchor' src="image/login/anchor.svg"/>
                            </i>
                          </button>
                        </a>
                      </div>
                      <div dcyour='' className="social_net" >
                        <a style={{margin: '10px 2px'}} href="https://twitter.com/dcyourchickens" target="_blank" rel='noreferrer'>
                          <img className="gg_d69" alt='socials' style={{height: '30px'}} src="image/svg/twitter.svg"/>
                        </a>
                        <a style={{margin: '10px 2px'}} href="https://discord.gg/yHXNSwwcyX" target="_blank" rel='noreferrer'>
                          <img className="gg_d69" alt='socials' style={{height: '24px'}} src="image/svg/discord.svg"/>
                        </a>
                        <a style={{margin: '10px 2px'}} href="https://wax.atomichub.io/market?collection_name=dcycofficial" target="_blank" rel='noreferrer'>
                          <img className="gg_d69" alt='socials' style={{width: '32px'}} src="image/svg/atomic.png"/>
                        </a>
                        <a style={{margin: '10px 2px'}} href="mailto:support@dcyourchickens.io" target="_blank" rel='noreferrer'>
                          <img className="gg_d69" alt='socials' style={{height: '32px'}} src="image/svg/email_.png"/>
                        </a>
                      </div>
                      <div className="footer_lg text-center hakki">
                        <p>Copyright 2022 © <a className="important-link" href="https://www.dcyourchickens.io/">DCYourChickens</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
  export default LoginPage;