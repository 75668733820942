//import '../../styles/hakkv_.css'
function Footer() {
    return (
        <div className="footer">
        <div className="copyright">
          <p>Copyright 2022 © <a href="https://www.dcyourchickens.io/">DCYourChickens</a>
          </p>
        </div>
      </div>
    );
  }
  export default Footer;