import '../../styles/market/store.app.css'
import '../../styles/shabes.css'
import { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';

function RefPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [referrer,setReferrer] = useState(params.ref)
  console.log("refpage")
  useEffect(()=>{
    localStorage.setItem("referrer",referrer)
    navigate("/auth")
  },[referrer])
    return (
        <div className="content-body">
        <div className="container-fluid">
        </div>
      </div>
    );
  }
  export default RefPage;