import '../../styles/inventory/backpack.css'
import Pagination from '../Pagination/Pagination';
import { GlobalStateContext } from '../Contexts/stateContext';
import { claimstolenAction } from '../controllers/sendController';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { stakeAssets } from '../controllers/sendController';
import { useHorizontalScroll } from '../Hooks/useSideScroll';
import { bulkUnstake, bulkCollectTax, collectTax, queueUnstake, cancelUnstake, levelup, claimLevelup, speedLevelup, repair } from '../controllers/missionsController';
import Countdown from '../Countdown/Countdown';
import { useNavigate } from "react-router-dom";
import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
const WEAPONS_TEMPLATES=["618020","618021","618022","618023","618024","618025","618026","618027",
"626582","626637","626907","626910","626912"]

const SweetalertModal = ({onValue,array,callback,ipfs}) =>{
  const [selected,setSelection] = useState([])
  useEffect(() => { onValue(selected) }, [selected])
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array.filter(item=> WEAPONS_TEMPLATES.find((chicken)=>item.template.template_id===chicken) )
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center">
                  <div onClick={()=>{selected.includes(item.asset_id)?setSelection(selected.filter((element)=>element!==item.asset_id)):setSelection([...selected,item.asset_id])}} className={["nft-container",selected.includes(item.asset_id)?"selected":""].join(" ")}>
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  </div>
                  <span>#{item.asset_id}</span>
              </div>)
              })
              }
          </div>
          </div>
  );
}

const UnstakeModal = ({onValue,array,callback,ual,refreshFunction,ipfs}) =>{
  const [selected,setSelection] = useState([])
  //console.log(userData.ipfs)
  useEffect(() => { onValue(selected) }, [selected])
  function update(){console.log("timer update")}
  function cancel(asset_id){
    cancelUnstake(ual,asset_id)
    .then(()=>Swal.fire({
      position: "center",
      icon: "success",
      title: "Processing transaction!",
      showConfirmButton: !1,
      timer: 2500
  }))
    .then(()=>refreshFunction())
  }
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array.filter(item=>WEAPONS_TEMPLATES.find((chicken)=>item.template.template_id===chicken) && item.availability.is_busy === 0)
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center">
                  <div style={{"position":"relative"}} onClick={()=>{selected.find((asset)=>asset.asset_id === item.asset_id)?setSelection(selected.filter((element)=>element.asset_id!==item.asset_id)):setSelection([...selected,{asset_id:item.asset_id,availability:item.availability}])}} className={["nft-container",selected.find((asset)=>asset.asset_id === item.asset_id)?"selected":""].join(" ")}>
                    {item.availability.is_queued===1 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000) &&
                    <i style={{"position":"absolute","fontSize":"35px","color":"darkgreen"}} className="fa-solid fa-check">
                    </i>
                    }
                    {item.availability.is_queued===1 && Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000) &&
                    <i style={{"position":"absolute","fontSize":"35px","color":"sandybrown"}} className="fa-solid fa-angles-left">
                    </i>
                    }
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  
                  </div>
                  <span>#{item.asset_id}</span>
                  <Countdown callback={update} sec={Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)}/>
                  {item.availability.is_queued===1&&<div onClick={()=>cancel(item.asset_id)} className="btn btn-primary">Cancel queue</div>}
              </div>)
              })
              }
          </div>
          </div>
  );
}

let PageSize = 7;
function Weaponry() {
  const {userData,getInventory,getStakedAssets,getSignupVerification,getAvailableToSteal} = useContext(GlobalStateContext)
  const ual = useContext(UALContext)
  const SelectorModal = withReactContent(Swal)
  let selectedFromModal;
  const navigate = useNavigate()

  const [completeCountdowns,addCompleteCountdown] = useState(0)
  const [currentTime,updateCurrentTime] = useState(new Date().getTime()/1000)
  useEffect(()=>{
    updateCurrentTime(new Date().getTime()/1000)
  },[completeCountdowns])

  const [currentPage, setCurrentPage] = useState(1);
  const [stakedFoxes,setStakedFoxes] = useState([])
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return userData.stakedChickens.filter(asset=>WEAPONS_TEMPLATES.find(fox=>asset.template.template_id===fox) ).slice(firstPageIndex, lastPageIndex);
  }, [currentPage,userData.stakedChickens]);
  useEffect(()=>{
    setStakedFoxes(userData.stakedChickens.filter(asset=>WEAPONS_TEMPLATES.find(fox=>asset.template.template_id===fox) ))
  },[userData.stakedChickens])


  let openUnstakeModal = () =>{
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<UnstakeModal ipfs={userData.ipfs} ual={ual} refreshFunction={getStakedAssets} onValue={value => { selectedFromModal = value }} array={userData.stakedChickens}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal.length>0){
        let to_unstake = selectedFromModal.filter((item)=>(item.availability.is_queued === 1 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)))
        let to_queue = selectedFromModal.filter((item)=>(item.availability.is_queued === 0 && Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)))
        console.log("queue",to_queue)
        console.log("unstake",to_unstake)
        if(to_queue.length>0){
          let assets = to_queue.map((item)=>item.asset_id)
          queueUnstake(ual,assets)
          .then(()=>Swal.fire({
            position: "center",
            icon: "success",
            title: "Assets sent to unstake queue",
            showConfirmButton: !1,
            timer: 1500
        }))
          .then(()=>getStakedAssets())
        }
        if(to_unstake.length>0){
          let assets = to_unstake.map((item)=>item.asset_id)
        bulkUnstake(ual,assets)
        .then(()=>getStakedAssets())
        .then(()=>getInventory())
        }
        
      }
      }
    })
  }


  let openStakeModal = () => {
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<SweetalertModal ipfs={userData.ipfs} onValue={value => { selectedFromModal = value }} array={userData.inventory}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal.length>0){
        stakeAssets(ual,selectedFromModal)
        .then(()=>getStakedAssets())
        .then(()=>getInventory())}
      }
    })
  }



  let openLvlModal = (asset_id,lvl,item) =>{
    //let lvl_object = userData.upgradeList.find((lvl_row)=>lvl_row.level_requirement === lvl && String(lvl_row.template_id)===item.template.template_id)
    let max_lvl = 0;
    if(String(item.template.template_id) === "618020" || String(item.template.template_id) === "626582"){max_lvl = 75}
    if(String(item.template.template_id) === "618021" || String(item.template.template_id) === "626637"){max_lvl = 70}
    if(String(item.template.template_id) === "618022" || String(item.template.template_id) === "626907"){max_lvl = 65}
    if(String(item.template.template_id) === "618023" || String(item.template.template_id) === "626910"){max_lvl = 60}
    if(String(item.template.template_id) === "618024" || String(item.template.template_id) === "626912"){max_lvl = 55}
    if(item.availability.is_busy===1||item.availability.is_queued===1){
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Upgrade unavailable! Weapon equipped/in unstake queue",
        showConfirmButton: !1,
        timer: 1500
      })
    }
    if(item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)){
      let lvls_object_array = []
      let total_lvl = 0
      let total_cost = 0
      let total_time_sec = 0
      Swal.fire({
        position: "center",
        icon: "question",
        title: "Choose level to upgrade",
        input: "range",
        inputAttributes: {
        min: lvl+1,
        max: max_lvl,
        step: 1
    },
    inputValue: lvl+1,
    preConfirm: (input)=>{
      for(let i = lvl;i<input;i++){
        let lvl_object = userData.upgradeList.find((lvl_row)=>lvl_row.level_requirement === i && String(lvl_row.template_id)===item.template.template_id)
        total_cost = total_cost+ Number(lvl_object.cost[0].quantity.split(" ")[0])
        total_time_sec = total_time_sec+lvl_object.level_duration
        lvls_object_array.push(lvl_object)
      }
      total_lvl=input
    }
      }).then((result)=>result.isConfirmed&&
      
      Swal.fire({
        position: "center",
        icon: "question",
        title: "Are you sure you want to lvl up?",
        text: `lvl ${lvl} -> ${total_lvl} | cost: ${total_cost.toFixed(8)} EGG | time: ${total_time_sec>60?Math.round(total_time_sec/60)+" min":total_time_sec+" sec"}`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: (input)=>{
          //console.log(lvls_object_array.map((row)=>{return row.level_up_id}))
          let lvls_arr = lvls_object_array.map((row)=>{return row.level_up_id})
          if(userData.internalEggsBalance>=total_cost){
            levelup(ual,item.asset_id,lvls_arr)
            //add 2sec delay
            .then(()=>
                      Swal.fire({
                        title: 'Processing transaction!',
                        timer: 4000,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        timerProgressBar: false,
                        didOpen: () => {
                          Swal.showLoading()
                        },
                      })
                      )
            .then(()=>getSignupVerification())
            .then(()=>getStakedAssets())
          }
          else{
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Not enough EGG",
              showConfirmButton: !1,
              timer: 1500
            })
          }
        }
      })
      )

  }

  if(item.availability.is_busy===0&&item.availability.is_queued===0&&(item.data.level_queue!==undefined&&item.data.level_queue.length>=0 && item.data.upgrade_available_at>0)&&Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)&&Math.round(new Date().getTime()/1000)>item.data.upgrade_available_at){
    claimLevelup(ual,item.asset_id)
    .then(()=>
                    Swal.fire({
                      title: 'Processing transaction!',
                      timer: 4000,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      allowEnterKey: false,
                      timerProgressBar: false,
                      didOpen: () => {
                        Swal.showLoading()
                      },
                    })
                    )
          .then(()=>getSignupVerification())
          .then(()=>getStakedAssets())
  }

  if(lvl>0&&item.availability.is_busy===0&&item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)<=Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)&&Math.round(new Date().getTime()/1000)<=item.data.upgrade_available_at){
    let previous_lvl_object = userData.upgradeList.find((lvl_row)=>lvl_row.level_up_id === Number(item.data.level_queue[0]))
    Swal.fire({
      position: "center",
      icon: "question",
      title: "Are you sure you want to speed up leveling?",
      text: `skip time: ${previous_lvl_object.level_duration} sec | cost: ${previous_lvl_object.speed_up_cost[0].quantity}`,
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: (input)=>{
        if(userData.internalEggsBalance>=previous_lvl_object.speed_up_cost[0].quantity.split(" ")[0]){
          speedLevelup(ual,item.asset_id,previous_lvl_object.level_up_id)
          //add 2sec delay
          .then(()=>
                    Swal.fire({
                      title: 'Processing transaction!',
                      timer: 4000,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      allowEnterKey: false,
                      timerProgressBar: false,
                      didOpen: () => {
                        Swal.showLoading()
                      },
                    })
                    )
          .then(()=>getSignupVerification())
          .then(()=>getStakedAssets())
        }
        else{
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Not enough EGG",
            showConfirmButton: !1,
            timer: 1500
          })
        }
      }
    })
  }


  }

  let showlvlupError = () =>{
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Asset is maxed",
      showConfirmButton: !1,
      timer: 1500
  })
  }

  let openRepairModal = (item) =>{
    //let lvl_object = userData.upgradeList.find((lvl_row)=>lvl_row.level_requirement === lvl && String(lvl_row.template_id)===item.template.template_id)
    let repair_row = userData.repairs.find((row)=> String(row.template_id) === item.template.template_id)
    let repair_max_durability = repair_row.max_durability
    let repair_durability_repaired = repair_row.durability_repaired
    let repair_cost = repair_row.cost[0].quantity.split(" ")[0]
    let current_durability = item.data.durability
    let repair_after = 0
    let repair_amount = 0
    if(item.availability.is_queued===1){
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Repair unavailable! Weapon in unstake queue",
        showConfirmButton: !1,
        timer: 1500
      })
    }
    if(item.availability.is_queued===0&&Math.round(new Date().getTime()/1000)>Math.round(new Date(item.availability.available_at+"Z").getTime()/1000)){
      let total_cost = 0
      Swal.fire({
        position: "center",
        icon: "question",
        title: "Choose durability amount to repair",
        input: "range",
        inputAttributes: {
        min: repair_durability_repaired,
        max: repair_max_durability-current_durability-repair_durability_repaired,
        step: repair_durability_repaired
    },
    inputValue: repair_durability_repaired,
    preConfirm: (input)=>{
      repair_after = parseInt(current_durability)+parseInt(input)
      repair_amount = parseInt(input)/parseInt(repair_durability_repaired)
      total_cost = parseFloat(repair_cost)*repair_amount
    }
      }).then((result)=>result.isConfirmed&&
      
      Swal.fire({
        position: "center",
        icon: "question",
        title: "Are you sure you want to repair current asset?",
        text: `durability ${current_durability}/${repair_max_durability} -> ${repair_after}/${repair_max_durability} | cost: ${total_cost.toFixed(8)} GOLD`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: (input)=>{
          if(userData.internalEggcoinsBalance>=total_cost){
            repair(ual,item.asset_id,repair_amount)
            //add 2sec delay
            .then(()=>
                      Swal.fire({
                        title: 'Processing transaction!',
                        timer: 4000,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        timerProgressBar: false,
                        didOpen: () => {
                          Swal.showLoading()
                        },
                      })
                      )
            .then(()=>getSignupVerification())
            .then(()=>getStakedAssets())
          }
          else{
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Not enough GOLD",
              showConfirmButton: !1,
              timer: 1500
            })
          }
        }
      })
      )

  }

  }

//onClick={()=>navigate(`/asset/${item.asset_id}`)}

  function update(){
    addCompleteCountdown((countdown)=>countdown+1)
  }

    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col mb-2">
            <div className="btn btn-primary" onClick={()=>navigate(`/craft`)}>
              Craft
            </div>
            </div> 
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="stake_mb">
                    <div className="picture_nft">
                      <img className="img-fluid" alt='add-nft' src="image/workspace/add_NFT.png"/>
                    </div>
                    <div className="content_card text-center mt-3">
                      <h4>
                        <div className="text-add">Add NFT</div>
                      </h4>
                      <div works="" className="buttons">
                        <div onClick={()=>openStakeModal()}  works="" className="common-button btn6 button-primary button-38">Stake</div>
                        <div onClick={()=>openUnstakeModal()}  works="" className="common-button btn5 button-38 outline">Unstake</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {currentTableData.map(item => {
                          return (
                            <div key={item.asset_id} className="col-xl-3 col-lg-6 col-sm-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="stake_mb">
                                  <div className="dcactives">
                                    <div className="picture_nft d-flex justify-content-end">
                                    <div title='level' style={{"position":"absolute","color":"white"}} className="d-flex"><span className='fs-2'>{item.data.level!==undefined?item.data.level:0}</span></div>
                                      <img className="img-fluid" referrerPolicy='no-referrer' alt='weapon_nft' src={"https://"+userData.ipfs+"/ipfs/"+item.data.img}/>
                                      <div className="multi-box">
                          <button title="upgrade" onClick={()=>((item.data.level === undefined)||(item.data.level!==undefined&&item.data.level<=75))?openLvlModal(item.asset_id,item.data.level||0,item):showlvlupError()} className={["fa-solid","btn3","fa-hand-back-fist",(item.availability.is_busy===0&&item.availability.is_queued===0&&item.data.level_queue&&item.data.upgrade_available_at>0&&item.data.level_queue.length>=0&&Math.round(new Date().getTime()/1000)>item.data.upgrade_available_at)?"available-to-steal":""].join(" ")}></button>
                          <button title="repair" onClick={()=>openRepairModal(item)} className={["fa-solid","btn3","fa-hammer"].join(" ")}></button>

                        </div>
                                    </div>
                                    {/*lvl up countdown*/(item.availability!==undefined&&item.data.upgrade_available_at!==undefined&&item.availability.is_busy===0&&item.availability.is_queued===0&&item.data.level_queue!==undefined&&item.data.level_queue.length>0&&Math.round(new Date().getTime()/1000)<=item.data.upgrade_available_at)&&
                        <div style={{"position":"relative","bottom":"35px"}} className="countdown">
                        <Countdown icon={"bolt"} callback={update} sec={(item.data.upgrade_available_at-Math.round(new Date().getTime()/1000)<=0)?0:item.data.upgrade_available_at-Math.round(new Date().getTime()/1000)}/>
                        </div>
                          }
                                    <Countdown callback ={update}  sec={Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(item.availability.available_at+"Z").getTime()/1000-new Date().getTime()/1000)} />
                                  </div>
                                  <div className="content_card text-center mt-3">
                                    <div className="item-bottom">
                                      <div className="value justify-content-center">
                                        <div className="value-text fs-5">{item.name!==undefined?item.name:"Weapon"}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                                );
                            })}
          </div>
        </div>
        <Pagination
                    currentPage={currentPage}
                    totalCount={stakedFoxes.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                    />
      </div>
    );
  }
  export default Weaponry;