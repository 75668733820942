import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/roost.vp.css'
import './styles/hakkv_.css'
import './styles/fontawesome.css'
import './styles/shabes.css'
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UALProvider, withUAL} from 'ual-reactjs-renderer'
import {Anchor} from 'ual-anchor'
import { Wax } from '@eosdacio/ual-wax';
import { GlobalStateProvider } from './components/Contexts/stateContext';

let rpc_host = localStorage.getItem("rpc") || "wax.greymass.com"
const wax_testnet = {
  chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
  rpcEndpoints: [{
    protocol: 'https',
    host: 'testnet.wax.pink.gg',
    port: '443',
  }]
}
const wax_mainnet = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
    protocol: 'https',
    host: rpc_host,
    port: '443',
  }]
}

const AppWithUAL = withUAL(App)

const anchor = new Anchor([wax_mainnet], {
  // Required: The app name, required by anchor-link. Short string identifying the app
  appName: 'DCYC',
  // Optional: a @greymass/eosio APIClient from eosjs for both your use and to use internally in UAL
  // client = new APIClient({ provider }),
  // Optional: a JsonRpc instance from eosjs for your use
  // rpc: new JsonRpc(),
  // Optional: The callback service URL to use, defaults to https://cb.anchor.link
  // service: 'https://cb.anchor.link',
  // Optional: A flag to disable the Greymass Fuel integration, defaults to false (enabled)
  // disableGreymassFuel: false,
  // Optional: An account name on a Fuel enabled network to specify as the referrer for transactions
  // fuelReferrer: 'teamgreymass',
  // Optional: A flag to enable the Anchor Link UI request status, defaults to true (enabled)
  // requestStatus: true,  
  // Optional: Whether or not to verify the signatures during user login, defaults to false (disabled)
  // verifyProofs: false,
})

const wax = new Wax([wax_mainnet],{
  appName:'DCYC',
  tryAutoLogin: false,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UALProvider chains={[wax_mainnet]} authenticators={[anchor,wax]} appName="DCYC">
      <GlobalStateProvider>
    <BrowserRouter>
    <AppWithUAL />
    </BrowserRouter>
      </GlobalStateProvider>
    </UALProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
