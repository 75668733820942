import '../../styles/market/store.app.css'
import '../../styles/shabes.css'
import { useContext,useState } from 'react';
import { GlobalStateContext } from '../Contexts/stateContext';
import { UALContext } from 'ual-reactjs-renderer';
import { claimBulk, transferEgg, transferWax } from '../controllers/sendController';
import Swal from 'sweetalert2';
import gold from "../../dcyc-gold.png"
import egg from "../../token-91.png"
const ipfs_list =["ipfs.io","gateway.pinata.cloud","ipfs.eth.aragon.network"]

function AccountPage() {
  const ual = useContext(UALContext)
  const {userData, getInventory,getUnclaimedAssets,getSignupVerification,getBalance,setIpfs} = useContext(GlobalStateContext)
  const [isEGG,setEGG] = useState(true)
  const [depositAmount,setDepositAmount] = useState(0)
    const claim = () =>{
        let rolls = userData.unclaimedAssets.map((asset)=>asset.index)
        claimBulk(ual,rolls).then(()=>getUnclaimedAssets())
    }
    let changeTokens = () =>{
      setEGG(!isEGG)
    }
    let callDeposit = () =>{
      if(isEGG){
        transferEgg(ual,Number(depositAmount).toFixed(8))
        .then(()=>
    Swal.fire({
      title: 'Processing transaction!',
      timer: 1000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
        .then(()=>getSignupVerification())
        .then(()=>getBalance())
      }
      else{
        transferWax(ual,Number(depositAmount).toFixed(8))
        .then(()=>
    Swal.fire({
      title: 'Processing transaction!',
      timer: 1000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
        .then(()=>getSignupVerification())
        .then(()=>getBalance())
      }
    }
    let handleChange = (event) => {
      setDepositAmount(event.target.value)
    }

    let handleSelect = (event) =>{
      setIpfs(event.target.value)
    }

    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">Account description.</ol>
          </div>
          <div className='d-flex' style={{"maxWidth":"100%","minWidth":"-webkit-fill-available","position":"absolute",zIndex:"-10"}}>
            <div style={{"position":"relative","display":"flex","maxWidth":"100%","minWidth":"50%","flexDirection":"column","marginTop":"250px"}} className="col halloween-container">
              <div className="row" style={{"justifyContent":"flex-end"}}>
                <div className="col moon" style={{"backgroundImage":`url("image/halloween/moon.png")`,"backgroundSize":"contain","position":"relative","height":"150px","max-width":"150px","zIndex":"-1","backgroundRepeat":"no-repeat"}}></div>
              </div>
              <div className="row" style={{"justifyContent":"center"}}>
                <div className="col bat" style={{"backgroundImage":`url("image/halloween/one-eye-bat.png")`,"backgroundSize":"contain","position":"relative","height":"150px","max-width":"150px","zIndex":"-1","backgroundRepeat":"no-repeat"}}></div>
              </div>
              <div className="row" style={{"justifyContent":"space-between"}}>
                <div className="col pumpkin" style={{"backgroundImage":`url("image/halloween/crazy-pumpkin.png")`,"backgroundSize":"contain","position":"relative","height":"150px","max-width":"150px","zIndex":"-1","backgroundRepeat":"no-repeat"}}></div>
                <div className="col pumpkin" style={{"backgroundImage":`url("image/halloween/fat-pumpkin.png")`,"backgroundSize":"contain","position":"relative","height":"150px","max-width":"150px","zIndex":"-1","backgroundRepeat":"no-repeat"}}></div>
              </div>

            </div>
          </div>

          <div className="row mb-5 align-items-center">
            <div className="col">
              <div className="card m-0">
                <div className="card-body px-4 py-3 py-lg-2 stats">
                  <div className="row align-items-center">
                    <div className="col-xl-3 col-xxl-12 col-lg-12 my-2">
                      <p className="mb-0 fs-14">Welcome back, {userData.username}!</p>
                    </div>
                    <div className="col-xl-7 col-xxl-12 col-lg-12">
                      <div className="row align-items-center">
                        <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                          <div className="media align-items-center">
                            <span className="me-3">
                              <img alt='waxp' src="image/token/waxp.png"/>
                            </span>
                            <div className="media-body ms-1">
                              <p className="mb-0 fs-12">WAXP</p>
                              <h4 className="mb-0 font-w600  fs-22">{Math.floor(userData.internalWax*100000)/100000}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                          <div className="media align-items-center">
                            <span className="me-3">
                              <img alt='egg-token' src={gold}/>
                            </span>
                            <div className="media-body ms-1">
                              <p className="mb-0 fs-12">GOLD</p>
                              <h4 className="mb-0 font-w600 fs-22">{userData.internalEggcoinsBalance}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                          <div className="media align-items-center">
                            <span className="me-3">
                            <img  alt='egg token' src={egg}/>
                            </span>
                            <div className="media-body ms-1">
                              <p className="mb-0 fs-12">$EGG</p>
                              <h4 className="mb-0 font-w600 fs-22">{Math.floor(userData.internalEggsBalance*100000)/100000}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center border-top">
                    <div className="col-xl-3 col-xxl-12 col-lg-12 my-2">
                      <p className="mb-0 fs-14">External balance:</p>
                    </div>
                    <div className="col-xl-7 col-xxl-12 col-lg-12">
                      <div className="row align-items-center">
                        <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                          <div className="media align-items-center">
                            <span className="me-3">
                              <img alt='waxp' src="image/token/waxp.png"/>
                            </span>
                            <div className="media-body ms-1">
                              <p className="mb-0 fs-12">WAXP</p>
                              <h4 className="mb-0 font-w600  fs-22">{userData.waxBalance}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 my-2">

                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 my-2">
                          <div className="media align-items-center">
                            <span className="me-3">
                            <img  alt='egg token' src={egg}/>
                            </span>
                            <div className="media-body ms-1">
                              <p className="mb-0 fs-12">$EGG</p>
                              <h4 className="mb-0 font-w600 fs-22">{Math.floor(userData.externalEgg*100000)/100000}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="deposit-row mb-3">
            <div className="col-sm col-md-4">
            <label htmlFor="depositAmount" className="form-label">{isEGG?"$EGG":"WAX"} amount</label>
              <input type="number" value={depositAmount} onChange={handleChange} className="form-control" id="depositAmount" min={0}/>
              <div className="btn-group" role="group">
                <div onClick={()=>callDeposit()} className="btn btn-primary ">Deposit</div>
                <div onClick={()=>changeTokens()} className="btn btn-primary ">Change</div>
                </div>
            </div>
          </div>

          <div className="fs-3">Ref link: <span style={{cursor:"pointer"}} onClick={()=>navigator.clipboard.writeText("https://"+window.location.hostname+"/ref/"+userData.username)} className='text-decoration-underline'>{window.location.hostname+"/ref/"+userData.username}</span> </div>
          <div className="refs fs-3">Referrals: {userData.referrals} </div>
          <div style={{cursor:"pointer"}} onClick={()=>userData.unclaimedAssets.length>0&&claim()} className="unclaimed fs-3 text-decoration-underline">Unclaimed assets:{userData.unclaimedAssets.length}</div>
          <div className="col-sm col-md-4 mt-3">
            <div className="input-group mb-3">
              <label className="input-group-text" htmlFor="inputGroupSelect">Ipfs</label>
              <select value={userData.ipfs} onChange={handleSelect} className="form-select" id="inputGroupSelect">
                {ipfs_list.map(hostname=>(
                <option style={{"color":"black","backgroundColor":"white"}} key={hostname} value={hostname}>{hostname}</option>
                ))}
                
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
  export default AccountPage;