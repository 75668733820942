import { useState, useRef, useEffect } from 'react';
function Countdown({sec,callback,icon}) {
const [timer, setTimer] = useState(sec);
const [delayHMS,setDelay] = useState({hours:0,minutes:0,secs:0})
const id = useRef(null);

const clear = () => {
    window.clearInterval(id.current);
};
useEffect(() => {
    if(sec>0){
        setTimer(sec)
    }
    id.current = window.setInterval(() => {
        setTimer((time) => time - 1);
        
        }, 1000);
    return () => clear();
}, [sec]);

//restart timer if prop changed
/*useEffect(() => {
    if (sec>0){
    setTimer(sec)
    id.current = window.setInterval(() => {
    setTimer((time) => time - 1);
    
    }, 1000);}
    return () => clear();
}, [sec]);*/

useEffect(() => {
    if (timer <=0) {
    let hours = 0
    let minutes = 0
    let secs = 0
    setDelay({hours,minutes,secs})

    callback()
    clear();
    }
    else{
    let hours = Math.floor(timer / 60 / 60);
    let minutes = Math.floor(timer / 60) - (hours * 60);
    let secs = timer % 60;
    setDelay({hours,minutes,secs})

    }
}, [timer]);




    return (
        <div className="delay-items">
            {
                icon==="bolt" &&
                <div className="fa-solid fa-bolt fs-4"></div>
            }
            {
                icon==="snowflake" &&
                <div style={{"color":"steelblue"}} className="fa-solid fa-snowflake fs-4"></div>
            }
            <div className="delay-item">
                <span className="num">{delayHMS.hours}</span>
            </div>
            <div className="delay-item">
                <span className="sep">:</span>
                <span className="num">{delayHMS.minutes}</span>
            </div>
            <div className="delay-item">
                <span className="sep">:</span>
                <span className="num">{delayHMS.secs}</span>
            </div>
        </div>
    );
  }
  export default Countdown;