//import '../../styles/hakkv_.css'
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function SideBar() {
  const location = useLocation()
  const [currentLocation,setLocation] = useState(location.pathname)
  useEffect(()=>{
    setLocation(location.pathname)
  },[location])
    return (
      <>
      {currentLocation!=="/maintenance"?(
        <div className="deznav">
            <div className="deznav-scroll ps">
          <ul className="metismenu">
            <li>
              <Link to="/workspace" className="ai-icon">
                <i className="fa-solid fa-feather"></i>
                <span className="nav-text">Chickens</span>
              </Link>
            </li>
            <li>
              <Link to="/inventory" className="ai-icon">
                <i className="fa-solid fa-box-archive"></i>
                <span className="nav-text">Inventory</span>
              </Link>
            </li>
            <li>
              <Link to="/market" className="ai-icon">
                <i className="fa-solid fa-store"></i>
                <span className="nav-text">Market</span>
              </Link>
            </li>
            <li>
              <Link to="/foxes" className="ai-icon">
                <i className="fa-brands fa-firefox-browser"></i>
                <span className="nav-text">Foxes</span>
              </Link>
            </li>
            <li>
              <Link to="/weaponry" className="ai-icon">
                <i className="fa-brands fa-hammer"></i>
                <span className="nav-text">Weaponry</span>
              </Link>
            </li>
            <li>
              <Link to="/kitty" className="ai-icon">
                <i className="fa-brands fa-cat"></i>
                <span className="nav-text">Kitty</span>
              </Link>
            </li>
            <li>
              <Link to="/exchange" className="ai-icon">
                <i className="fa-solid fa-scale-balanced"></i>
                <span className="nav-text">Exchange</span>
              </Link>
            </li>
            
            <li>
              <Link to="/account" className="ai-icon">
                <i className="fa-solid fa-user-astronaut"></i>
                <span className="nav-text">Account</span>
              </Link>
            </li>
            <li>
              <Link to="/leaderboard" className="ai-icon">
                <i className="fa-solid fa-medal"></i>
                <span className="nav-text">Leaderboard</span>
              </Link>
            </li>
            <li>
              <a  className="ai-icon">
                <i className="fa-solid fa-tag"></i>
                <span className="nav-text">Important</span>
              </a>
            </li>
          </ul>
        <div className="ps__rail-x" style={{left: '0px',top: '0px'}}><div className="ps__thumb-x"  style={{left: '0px', width: '0px'}}></div></div><div className="ps__rail-y" style={{top: '0px', left: '0px'}}><div className="ps__thumb-y"  style={{top: '0px', height: '0px'}}></div></div></div>
        </div>):null}
        
      
      </>
    );
  }
  export default SideBar;