import '../../styles/market/store.app.css'
import '../../styles/shabes.css'
import { transferWax, transferAsset, unpackMemo, transferEgg } from '../controllers/sendController';
import { useContext } from 'react';
import { GlobalStateContext } from '../Contexts/stateContext';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import egg from "../../token-91.png"
import gold from "../../dcyc-gold.png"
import { buyListing } from '../controllers/missionsController';

function MarketPage() {
  const ual = useContext(UALContext)
  const {userData, getInventory, getSignupVerification, getBalance} = useContext(GlobalStateContext)
  async function initTransfer(){
    if(userData.eggNFTs.length>0){
    await transferAsset(ual,userData.eggNFTs[0].asset_id,1)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }

  async function initUnpackClass4risk(){
    if(userData.riskPackClassFour.length>0){
    await transferAsset(ual,userData.riskPackClassFour[0].asset_id,6)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }

  async function initUnpackClass4safe(){
    if(userData.safePackClassFour.length>0){
    await transferAsset(ual,userData.safePackClassFour[0].asset_id,7)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }

  async function initUnpackClass5risk(){
    if(userData.riskPackClassFive.length>0){
    await transferAsset(ual,userData.riskPackClassFive[0].asset_id,8)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }

  async function initUnpackClass5safe(){
    if(userData.safePackClassFive.length>0){
    await transferAsset(ual,userData.safePackClassFive[0].asset_id,9)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }


  async function initUnpackClass4bomb(){
    if(userData.bombNFT.length>0){
    await transferAsset(ual,userData.bombNFT[0].asset_id,10)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }

  async function initUnpackClass3(){
    if(userData.thirdClassPacks.length>0){
    await transferAsset(ual,userData.thirdClassPacks[0].asset_id,4)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }

  async function initUnpackBomb(){
    if(userData.bombPacks.length>0){
    await transferAsset(ual,userData.bombPacks[0].asset_id,3)
    .then(()=>getInventory())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }




  async function initEggUnpack(){
    if(userData.eggPacks.length>0){
    unpackMemo(ual,userData.eggPacks[0].asset_id,"burn")
    .then(()=>//2.5sec delay
    Swal.fire({
      title: 'Unpacking!',
      timer: 1500,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    //.then(()=>transferEgg(ual,Number(150).toFixed(8)))
    .then(()=>getInventory())
    .then(()=>getBalance())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }

  async function initEggMiniUnpack(){
    if(userData.eggPacksMini.length>0){
    unpackMemo(ual,userData.eggPacksMini[0].asset_id,"burn")
    .then(()=>//2.5sec delay
    Swal.fire({
      title: 'Unpacking!',
      timer: 1500,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    //.then(()=>transferEgg(ual,Number(65).toFixed(8)))
    .then(()=>getInventory())
    .then(()=>getBalance())

  }
    else {Swal.fire({
      position: "center",
      icon: "error",
      title: "No packs",
      showConfirmButton: !1,
      timer: 1500
  })}
  }


  function initBuy(listing_id){
    buyListing(ual,listing_id,1)
    .then(()=>//2.5sec delay
    Swal.fire({
      title: 'Processing transaction!',
      timer: 4000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getInventory())
    .then(()=>getSignupVerification())
  }
    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">Before making your purchase, you will need to submit an NFT token.</ol>
          </div>
          <div packsmk="" className="market-layout">
            <div packsmk="" className="section-data">
              <div packsmk="" className="grid_nft justify-content-center">

              <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/in-game-pack-class-3.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">In-Game Pack 3</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.thirdClassPacks.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initUnpackClass3()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>


                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/risky-chicken-pack-class-4.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Risky Pack 4</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.riskPackClassFour.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initUnpackClass4risk()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>


                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/safe-chicken-pack-class-4.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Safe Pack 4</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.safePackClassFour.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initUnpackClass4safe()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>


                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/risky5.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Risky Pack 5</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        <img yuhk="" alt='egg-token' src={egg}/>140 <span yuhk=""> EGG</span>
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                      <div botrx="" onClick={()=>initBuy(7)} className="buy-button btn7 butt1 button-ah">Buy Now</div>
                    </div>
                  </div>
                </div>
                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/risky5.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Risky Pack 5</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.riskPackClassFour.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initUnpackClass5risk()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>

                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/safe5.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Safe Pack 5</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        <img yuhk="" alt='egg-token' src={egg}/>150 <span yuhk=""> EGG</span>
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                      <div botrx="" onClick={()=>initBuy(8)} className="buy-button btn7 butt1 button-ah">Buy Now</div>
                    </div>
                  </div>
                </div>
                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/safe5.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Safe Pack 5</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.safePackClassFive.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initUnpackClass5safe()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>


                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/ingame-fox-pack-class-5.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">In-Game Fox Pack</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.bombNFT.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initUnpackClass4bomb()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>

                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/bomb-pack.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Bomb Pack</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.bombPacks.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initUnpackBomb()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>


                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/egg-box-comp.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">EGG Pack</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.eggPacks.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initEggUnpack()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>

                <div className="item_market section-ux">
                  <div className="item-top">
                    <div className="avatar">
                      <div estiv="" className="roost-box square-gm">
                        <div estiv="" className="picture-view" style={{backgroundImage: 'url(image/market/egg-box-comp-small.png)'}}></div>
                      </div>
                    </div>
                    <div packtxt="" className="title">Mini EGG Pack</div>
                    <div yuhk="" packtxt="" className="payment-price-view">
                      <div yuhk="" className="payment-value">
                        x{userData.eggPacksMini.length}
                      </div>
                    </div>
                    <div botrx="" className="butrex">
                    <div botrx="" onClick={()=>initEggMiniUnpack()} className="buy-button btn7 butt1 button-ah">Unpack</div>
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  export default MarketPage;