import '../../styles/market/store.app.css'
import '../../styles/shabes.css'
import '../../styles/leaderb/lead.fx.css'
import '../../styles/leaderb/lead.wl.css'
import { transferWax, transferAsset, unpackMemo, transferEgg } from '../controllers/sendController';
import { useContext } from 'react';
import { GlobalStateContext } from '../Contexts/stateContext';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import egg from "../../token-91.png"
import gold from "../../dcyc-gold.png"
import { buyListing } from '../controllers/missionsController';

function AlphaWL() {
  const ual = useContext(UALContext)
  const {userData, getInventory, getSignupVerification, getBalance} = useContext(GlobalStateContext)
  
  
    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">Your rank on the league table is #270 and your EGG production power is 3254.</ol>
          </div>
          <div className="tabs_wrap">
            <ul>
              <li data-tabs="player" className="active">
                <a href="leaderb.html">
                <i className="fa-solid fa-feather-pointed"></i>
                </a> Player
              </li>
              <li data-tabs="pvp">
                <i className="fa-brands fa-firefox-browser"></i> PVP
              </li>
              <li data-tabs="alphawl">
                <a href="leadewl.html">
                <i className="fa-solid fa-square-poll-vertical"></i>
                </a> Alpha WL
              </li>
            </ul>
          </div>
          <div className="lead_wl">
            <div className="grid" >
              <li className="player_xh">
                <div className="img_dcyC2">
                  <img className="bg-image" src="image/profile/rooster_.png"/>
                  <div className="rank_wl hiskent_kw">
                    <img src="image/leaderb/one.png"/>
                  </div>
                </div>
                <div className="player-crd">
                  <div className="wax_wl">
                    hakkv.dev 
                  </div>
                  <small>Gold Mined <strong className="mine_gold">3447</strong>
                  </small>
                </div>
                <div>
                  <div className="dwsh percent">
                    <div className="bart">
                      <div className="izzy"></div>
                    </div>
                  </div>
                  <span>
                  <em>
                  <b>180</b> votes </em>
                  <em>77%</em>
                  </span>
                </div>
                <div>
                  <button className="vote_bt btn10"> Vote
                  </button>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    );
  }
  export default AlphaWL;