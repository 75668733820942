import React from 'react';
import { useId } from 'react';
import { usePagination, DOTS } from '../Hooks/usePagination';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul
      className="pagination ivpg"
      
    >
       {/* Left navigation arrow */}
      <li
        className={["page-item","page-indicator",currentPage === 1?"arrow_disabled":""].join(" ")}
        onClick={onPrevious}
      >
        <div className="page-link">
                          <i className="fa-solid fa-chevron-left"></i>
                        </div>
      </li>
      
      {paginationRange.map((pageNumber,index) => {
         
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          
          return <li key={pageNumber+index}className="pagination-item dots">&#8230;</li>;
        }
		
        // Render our Page Pills
        return (
          <li key={pageNumber}
            className={["page-item",pageNumber === currentPage?"active":""].join(" ")}
              //selected={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
          >
            <div className="page-link">{pageNumber}</div>
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        className={["page-item","page-indicator",currentPage === lastPage?"arrow_disabled":""].join(" ")}
        onClick={onNext}
      >
        <div className="page-link">
                          <i className="fa-solid fa-chevron-right"></i>
                        </div>
      </li>
    </ul>
  );
};

export default Pagination;