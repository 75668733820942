import '../../styles/inventory/backpack.css'
import { useContext, useState, useMemo } from 'react';
import { GlobalStateContext } from '../Contexts/stateContext';
import Pagination from '../Pagination/Pagination';
let PageSize = 10;
function InventoryPage() {
  const {userData} = useContext(GlobalStateContext)
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return userData.inventory.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
 
    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">All your NFT assets are here.</ol>
          </div>
          <div className="detail-layout-page">
            <div className="detail-layout">
              <div className="detail-aside">
                <div backpack="" className="left-aside-bpack">
                  <div className="scarx_dc">
                    <div className="avatar">
                      <div className="character-contnent">
                        <img className="img-vix" alt='captain-wolf' src="image/inventory/tyson-the-pyro-comp.png"/>
                      </div>
                    </div>
                  </div>
                  <div backpack="" className="title">NFT Assets</div>
                  <div backpack="" className="buttons">
                    <a href='https://wax.atomichub.io/market?collection_name=dcycofficial' backpack="" className="butying botrxr button-nt">Buy on AtomicHub</a>
                  </div>
                  <div backpack="" className="num_activs">You have: <b backpack="">{userData.inventory.length}</b>
                  </div>
                  <div backpack="" className="notebx">Note: Your NFTs will automatically appear in this section after you have purchased them.</div>
                </div>
              </div>
              <div className="detail-right">
                <section backpack="" className="detail-box-view right-view">
                  <div className="box-content">
                    <div backpack="" className="nft-table-list">
                      <div backpack="" className="table-inner">
                        <div backpack="" className="table-item table-header">
                          <div backpack="" className="td-item flex-left">Name</div>
                          <div backpack="" className="td-item">NFT ID</div>
                          <div backpack="" className="td-item">EGG Production</div>
                          <div backpack="" className="td-item">Rarity</div>
                        </div>
                        <div backpack="" className="table-container">
                          {currentTableData.map(item => {
                          return (
                          <div key={item.asset_id} backpack="" className="table-item">
                            <div backpack="" className="td-item flex-left">
                              <div backpack="" className="icon">
                                <div listbx="" backpack="" className="nft-alpha-box square-box small">
                                  <div listbx="" className="inner-view" style={{backgroundImage: ["url(https://"+userData.ipfs+"/ipfs/",item.data.img,")"].join("")}}></div>
                                </div>
                              </div>
                              <div backpack="" className="title">{item.name}</div>
                            </div>
                            <div backpack="" className="td-item">{"#"+item.asset_id}</div>
                            <div backpack="" className="td-item bold">0/hr</div>
                            <div backpack="" className="td-item">None</div>
                          </div>
                                );
                            })}
                        </div>
                      </div>
                    </div>
                    <Pagination
                    currentPage={currentPage}
                    totalCount={userData.inventory.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  export default InventoryPage;