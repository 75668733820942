import '../../styles/market/store.app.css'
import '../../styles/shabes.css'
import '../../styles/maintce/mant.css'
import { transferWax, transferAsset, unpackMemo, transferEgg } from '../controllers/sendController';
import { useContext } from 'react';
import { GlobalStateContext } from '../Contexts/stateContext';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import egg from "../../token-91.png"
import gold from "../../dcyc-gold.png"
import { buyListing } from '../controllers/missionsController';

function Maintenance() {
  const ual = useContext(UALContext)
  const {userData, getInventory, getSignupVerification, getBalance} = useContext(GlobalStateContext)
  
  
    return (
    <div class="maint-body">
        <div class="container-fluid">
          <div class="cpmai-content">
            <div class="cpmai-thumb">
              <img src="image/maintce/pngegg.png" alt="Maintenance DCYC"/>
            </div>
            <h2>Maintenance DCYourChickens</h2>
            <h4>We are adding improvements to the interface, we will be back soon!</h4>
            <section class="message warning">
              <i></i>
              <h2>
                <span>Warning!</span>
                Do not interact with the site while we are working.
              </h2>
            </section>
          </div>
        </div>
      </div>
    );
  }
  export default Maintenance;