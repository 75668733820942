
import { createContext, useState, useContext, useEffect } from "react";
import { UALContext } from "ual-reactjs-renderer";
import { ExplorerApi } from 'atomicassets';
export const GlobalStateContext = createContext();
//"https://atomic-wax-mainnet.wecan.dev" 1day cache
//"https://wax.api.atomicassets.io"
//https://wax.api.aa.atomichub.io
//https://wax-mainnet-ah.api.atomichub.io
//https://atomic-wax-mainnet.wecan.dev/atomicassets/v1/assets?ids=1099816808288&page=1&limit=100&order=desc&sort=asset_id
//https://wax-aa.eu.eosamsterdam.net  eu|hetzner|1k limit
const assetsAPI = new ExplorerApi("https://wax.api.atomicassets.io", "atomicassets", {fetch}); // main api
const mirrorAPI = new ExplorerApi("https://wax-aa.eu.eosamsterdam.net", "atomicassets", {fetch});// mirror api if main is 403
const COLLECTION_NAME = "dcycofficial"
const ipfs_gateways = ["ipfs.io","gateway.pinata.cloud","ipfs.eth.aragon.network"]
const CHICKEN_TEMPLATES=["583633","601886","601889","601890","601899","601919","601925","601926","601929","601930","601933","601934","601935","601936","601937","601938","601939"]
const FOX_TEMPLATES=["602317","602320","602321","602324","602327","602328","602331","602332","602333","602335"]
const WEAPONS_TEMPLATES=["618020","618021","618022","618023","618024","618025","618026","618027",
"626582","626637","626907","626910","626912"]
const initialState = {
    username:"",
    waxBalance:0,
    internalEggsBalance:0,
    internalEggcoinsBalance:0,
    internalWax:0,
    externalEgg:0,
    eggPacks:[],
    eggPacksMini:[],
    maxSlots:120,
    maintenance:false,
    eggNFTs:[],
    bombPacks:[],
    secClassPacks:[],
    thirdClassPacks:[],
    weapons:[],
    riskPackClassFour:[],
    safePackClassFour:[],
    riskPackClassFive:[],
    safePackClassFive:[],
    foxPackClassFour:[],
    bombNFT:[],
    foxes:[],
    inventory:[1],
    stolennfts:[],
    missionsList:[],
    stakedChickens:[],
    currentMissions:[],
    unclaimedAssets:[],
    repairs:[],
    leaderboard:[],
    upgradeList:[],
    unclaimedRewards:[],
    swapPool:{},
    referrals:0,
    isSignedUp:true,
    ipfs:localStorage.getItem("ipfs")||"ipfs.io",
    rpc:"" 
}
  
  // We wrap the provider in a nice little component
  // which will hold the state and provide methods to
  // update the state
  function GlobalStateProvider(props) {
    const [userData, setUserData] = useState(initialState);
    const ual = useContext(UALContext)
    useEffect(()=>{
        if(ual.activeUser!==null && ual.activeUser!==undefined){
            userData.rpc = ual.activeUser.rpc
            userData.username = ual.activeUser.accountName
            getConfig()
            getBalance()
            getInventory()
            getMissions()
            getSignupVerification()
            getStakedAssets()
            getRepairsList()
            getSwapPool()
            getLeaderboard()
            getUnclaimedAssets()
            getUpgradesList()
            getUnclaimedRewards()
            console.log("current account: "+ual.activeUser.accountName)
        }
        if (ual.activeUser === null) {setDefaultState()}
        
    },[ual.activeUser])

    useEffect(()=>{
      if(ual.activeUser){
      getAvailableToSteal()}
    },[userData.foxes])

    useEffect(()=>{
      localStorage.setItem("ipfs",userData.ipfs)
    },[userData.ipfs])

    async function getBalance(){
        let info = await ual.activeUser.rpc.get_account(userData.username)
        console.log(info)
        let waxBalance = info.core_liquid_balance.split(" ")[0]||0
        waxBalance = Math.round(waxBalance*100000)/100000
        let externalEgg = await ual.activeUser.rpc.get_currency_balance("dcyctokeneos",ual.activeUser.accountName,"EGG")
        let exEgg = 0||(externalEgg.length>0?externalEgg[0].split(" ")[0]:0)
        console.log("wax: "+waxBalance,"egg: "+externalEgg)
        setUserData((prevState)=>({...prevState,waxBalance,externalEgg:exEgg}))
      }
      async function getAvailableToSteal(){
        let stolennfts_table ={
          json: true,               // Get the response as json
          code: 'dcycstealing',      // Contract that we target
          scope: 'dcycthiefnft',         // scope (pools) currently only one pool is available
          table: 'stolennfts',
          limit: 300,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false          // Optional: Show ram payer
          //lower_bound: "" - optional
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        let stolennfts = []
        
        
        let availableList = await ual.activeUser.rpc.get_table_rows(stolennfts_table)
        if(typeof availableList.rows !== 'undefined' && availableList.rows.length > 0){
          //test2 = nfts2.filter(({start_weight})=> foxes.some(({weight_start})=>weight_start==start_weight))
          stolennfts =  availableList.rows// to compare foxes weight and steal weight .filter(({start_weight})=> foxes.some(({data})=>data.weight_start == start_weight))
        }
        //console.log(stolennfts)
        setUserData((prevState)=>({...prevState,stolennfts}))
      }

      function setIpfs(hostname){
        if (ipfs_gateways.find(ipfs=>ipfs===hostname)){
        setUserData((prevState)=>({...prevState,ipfs:hostname}))
        }
        
      }

      async function getUnclaimedAssets(){
        let rolls_table ={
          json: true,               // Get the response as json
          code: 'dcycstealing',      // Contract that we target
          scope: 'dcycstealing',         // scope (pools) currently only one pool is available
          table: 'rolls',
          limit: 200,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          lower_bound: ual.activeUser.accountName,// - optional
          //upper_bound: "dcyctestnet1",// - optional
          key_type:"name",
          index_position: 2// - Optional: Table secondary index
        }
        let unclaimedAssets = []
        
        
        let unclaimedList = await ual.activeUser.rpc.get_table_rows(rolls_table)
        
        if(typeof unclaimedList.rows !== 'undefined' && unclaimedList.rows.length > 0){
          //test2 = nfts2.filter(({start_weight})=> foxes.some(({weight_start})=>weight_start==start_weight))
          unclaimedAssets =  unclaimedList.rows.filter(({owner})=>owner===ual.activeUser.accountName)
          console.log("unclaimed",unclaimedAssets)
        }
        //console.log(stolennfts)
        setUserData((prevState)=>({...prevState,unclaimedAssets}))
      }

      async function getUnclaimedRewards(){
        let rolls_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: 'dcycmissions',         // scope (pools) currently only one pool is available
          table: 'rngrolls',
          limit: 200,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          lower_bound: ual.activeUser.accountName,// - optional
          //upper_bound: "dcyctestnet1",// - optional
          key_type:"name",
          index_position: 2// - Optional: Table secondary index
        }
        let unclaimedAssets = []
        
        
        let unclaimedList = await ual.activeUser.rpc.get_table_rows(rolls_table)
        
        if(typeof unclaimedList.rows !== 'undefined' && unclaimedList.rows.length > 0){
          //test2 = nfts2.filter(({start_weight})=> foxes.some(({weight_start})=>weight_start==start_weight))
          unclaimedAssets =  unclaimedList.rows.filter(({owner})=>owner===ual.activeUser.accountName)
          
        }
        console.log("unclaimed rewards",unclaimedAssets)
        //console.log(stolennfts)
        setUserData((prevState)=>({...prevState,unclaimedRewards:unclaimedAssets}))
      }

    async function getInventory(){
        let filter ={
          owner:userData.username,
          collection_name:COLLECTION_NAME,
        }
        const inventory = await assetsAPI.getAssets(filter,1,400).catch((error)=>{console.warn("got an error on main api",error);return mirrorAPI.getAssets(filter,1,400)})
        let eggNFTs = inventory.filter(item=>item.template.template_id=="604757")
        let secClassPacks = inventory.filter(item=>item.template.template_id=="612573")
        let foxes = inventory.filter(item=>item.template.template_id=="411261")
        let eggPacks = inventory.filter(item=>item.template.template_id=="597131")
        let eggPacksMini = inventory.filter(item=>item.template.template_id=="597141")
        let bombPacks = inventory.filter(item=>item.template.template_id=="613398")
        let thirdClassPacks = inventory.filter(item=>item.template.template_id=="621269")
        /**
         *     riskPackClassFour:[],
    safePackClassFour:[],
    foxPackClassFour:[],
         */
        let riskPackClassFour = inventory.filter(item=>item.template.template_id=="639947")
        let safePackClassFour = inventory.filter(item=>item.template.template_id=="639976")

        let riskPackClassFive = inventory.filter(item=>item.template.template_id=="645473")
        let safePackClassFive = inventory.filter(item=>item.template.template_id=="645472")

        let bombNFT = inventory.filter(item=>item.template.template_id=="614601")
        console.log("inventory",inventory)
        setUserData((prevState)=>({...prevState,inventory,eggNFTs,foxes,eggPacks,eggPacksMini,secClassPacks,bombPacks,thirdClassPacks,riskPackClassFour,safePackClassFour, riskPackClassFive, safePackClassFive,bombNFT}))
        console.log("refresh inventory")
      }

      async function getMissions(){
        let missions=[]
        let lower_bound_key = ""
        let more = true
        while (more){
        let missions_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: 'dcycmissions',         // scope (pools) currently only one pool is available
          table: 'missions',
          limit: 410,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          lower_bound:lower_bound_key
          //lower_bound: "" - optional
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        
        let missionsList = await ual.activeUser.rpc.get_table_rows(missions_table)
        missions = missions.concat(missionsList.rows)
        more = missionsList.more
        lower_bound_key = missionsList.next_key
        console.log("missionslist:",missionsList)
      }
        setUserData((prevState)=>({...prevState,missionsList:missions}))
        console.log("refresh missions")
        
        
      }

      async function getStakedAssets(){

        let staked=[]
        let lower_bound_key_staked = ""
        let more_staked = true

        let missions=[]
        let lower_bound_key_missions = ""
        let more_missions = true
        while (more_staked){
        let staked_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: ual.activeUser.accountName,         // scope (pools) currently only one pool is available
          table: 'staked',
          limit: 300,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          lower_bound:lower_bound_key_staked
          //lower_bound: "" - optional
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        
        let stakedList = await ual.activeUser.rpc.get_table_rows(staked_table)
        staked = staked.concat(stakedList.rows)
        more_staked = stakedList.more
        lower_bound_key_staked = stakedList.next_key
      }

      while (more_missions){
        let missions_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: ual.activeUser.accountName,         // scope (pools) currently only one pool is available
          table: 'usermissions',
          limit: 300,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          lower_bound:lower_bound_key_missions
          //lower_bound: "" - optional
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        
        let missionsList = await ual.activeUser.rpc.get_table_rows(missions_table)
        missions = missions.concat(missionsList.rows)
        more_missions = missionsList.more
        lower_bound_key_missions = missionsList.next_key
      }

        let chickensWithMissions = []
        if (staked.length>0){

        let ids = staked.map(item=>{return item.asset_id})
        let filter ={
          ids,
          owner:"dcycmissions"
        }
        const chickens = await assetsAPI.getAssets(filter,1,staked.length).catch((error)=>{console.warn("got an error on main api",error);return mirrorAPI.getAssets(filter,1,staked.length)})
        chickensWithMissions = chickens.map((chicken)=> {return {...chicken,availability:staked.find((asset)=>asset.asset_id === chicken.asset_id),associatedMission:missions.find((mission)=>mission.index===chicken.asset_id || (mission.asset_ids>1 && mission.asset_ids[1]===chicken.asset_id)),isWeapon:WEAPONS_TEMPLATES.find(id=>id===chicken.template.template_id)?true:false}})
      }
        
        console.log("chickens with missions")
        console.log(chickensWithMissions)
        setUserData((prevState)=>({...prevState,stakedChickens:chickensWithMissions}))
        //console.log(chickens)
      }


      async function getUpgradesList(){
        let lvls=[]
        let lower_bound_key = ""
        let more = true
        while (more){
        let upgrades_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: 'dcycmissions',         // scope (pools) currently only one pool is available
          table: 'levelup',
          limit: 410,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          lower_bound:lower_bound_key
          //lower_bound: "" - optional
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        let upgradeListrows = await ual.activeUser.rpc.get_table_rows(upgrades_table)
        lvls = lvls.concat(upgradeListrows.rows)
        more = upgradeListrows.more
        lower_bound_key = upgradeListrows.next_key
      }
        
        setUserData((prevState)=>({...prevState,upgradeList:lvls}))
        console.log("upgrades list",lvls)
      }

      async function getCurrentMissions(){
        let usermission_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: ual.activeUser.accountName,         // scope 
          table: 'staked',
          limit: 100,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false          // Optional: Show ram payer
          //lower_bound: "" - optional
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        
        let currentMissions = await ual.activeUser.rpc.get_table_rows(usermission_table)
        setUserData((prevState)=>({...prevState,currentMissions}))
        console.log("refresh missions")
      }

      async function getRepairsList(){
        let repairs_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: "dcycmissions",         // scope 
          table: 'repairs',
          limit: 100,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false          // Optional: Show ram payer
          //lower_bound: "" - optional
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        
        let repairsList = await ual.activeUser.rpc.get_table_rows(repairs_table)
        let repairs = repairsList.rows
        setUserData((prevState)=>({...prevState,repairs}))
        console.log("refresh repairs")
      }

      function setDefaultState(){
        setUserData(initialState)
      }

      async function refreshAllStats(){
        getBalance()
        getInventory()
        getSignupVerification()
        getStakedAssets()
        getSwapPool()
        getUnclaimedAssets()
        getUnclaimedRewards()
        //getAvailableToSteal()
      }

      /*
        
      */
      async function getSignupVerification(){
        let users_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: 'dcycmissions',         // scope 
          table: 'users',
          limit: 1,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          lower_bound: ual.activeUser.accountName 
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        let isSignedUp = false;
        let referrals = 0;
        let internalEggsBalance = 0;
        let internalEggcoinsBalance = 0;
        let internalWax = 0;
        let userList = await ual.activeUser.rpc.get_table_rows(users_table)
        if(typeof userList.rows !== 'undefined' && userList.rows.length > 0){
          if(userList.rows[0].owner === ual.activeUser.accountName){
            isSignedUp = true
            referrals = userList.rows[0].referrals
            internalEggcoinsBalance = userList.rows[0].tokens[0].quantity.split(" ")[0]
            internalEggsBalance = userList.rows[0].tokens[1].quantity.split(" ")[0]
            internalWax = userList.rows[0].tokens[2].quantity.split(" ")[0]
            /*if(eggcoins.length>0){
              internalEggsBalance = eggcoins[0].quantity.split(" ")[0]
            }*/
          }
        }
        console.log("account_stats refresh",internalEggsBalance)
        setUserData((prevState)=>({...prevState,isSignedUp,referrals,internalEggsBalance,internalEggcoinsBalance,internalWax}))
        
      }

      //getConfig

      async function getConfig(){
        let config_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: 'dcycmissions',         // scope 
          table: 'config',
          limit: 1,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          //lower_bound: ""
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        let slots = initialState.maxSlots;
        let maintenance = false
        let configRow = await ual.activeUser.rpc.get_table_rows(config_table)
        if(typeof configRow.rows !== 'undefined' && configRow.rows.length > 0){
          
          let configObj = configRow.rows[0]
          slots = configObj.params.max_user_missions
          maintenance = Boolean(configObj.maintenance)
          
        }
        setUserData((prevState)=>({...prevState,maxSlots:slots,maintenance}))
        
      }

      async function getLeaderboard(){
        try {
          let board = await fetch("https://dcycapi.onrender.com/leaderboard")
          let jsoned = await board.json()
          setUserData((prevState)=>({...prevState,leaderboard:jsoned}))
          console.log("leaderboard",jsoned)
        } catch (error) {
          console.log(error)
        }
        
      }

      async function getSwapPool(){
        let swaps_table ={
          json: true,               // Get the response as json
          code: 'dcycmissions',      // Contract that we target
          scope: 'dcycmissions',         // scope 
          table: 'swaps',
          limit: 1,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false,          // Optional: Show ram payer
          //lower_bound: ""
          //upper_bound: "" - optional
          //index_position: 2 - Optional: Table secondary index
        }
        
        let swapPool = {};
        let swapsList = await ual.activeUser.rpc.get_table_rows(swaps_table)
        if(typeof swapsList.rows !== 'undefined' && swapsList.rows.length > 0){
          
          swapPool = swapsList.rows[0]
          
        }
        setUserData((prevState)=>({...prevState,swapPool}))
        console.log("swap",swapPool)
        
      }

    const _userData = { userData, getInventory, getStakedAssets, getSignupVerification, getSwapPool, getUnclaimedAssets,getAvailableToSteal, getBalance, getUnclaimedRewards, setIpfs};
  
    return <GlobalStateContext.Provider value={_userData} {...props} />;
  }
  
  
  export { GlobalStateProvider};