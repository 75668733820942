/**
 * Action structure
 * @param  {string}account name of smart contract
 * @param  {string}name name of smart contract action
 * @param  {{}} authorization array of permissions
 * @param  {{}}data action data
 * 
 */

export let Action = function(account,name,authorization,data){
 this.account = account;
 this.name = name;
 this.authorization = authorization;
 this.data = data;
}

export class ActionBuilder {
    constructor(){
        let account;
        let name;
        let authorization;
        let data;  
    }

    setActionName(name) {
        this.name = name;
        return this;
    }
    setActionAccount(account) {
        this.account = account;
        return this;
    }
    setAuthorization(authorization) {
        this.authorization = authorization;
        return this;
    }
    setData(data) {
        this.data = data;
        return this;
    }
    build() {
        return new Action(this.account, this.name, this.authorization, this.data);
    }
}



