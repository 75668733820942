import '../../styles/inventory/backpack.css'
import Pagination from '../Pagination/Pagination';
import { GlobalStateContext } from '../Contexts/stateContext';
import { claimstolenAction, transferNeftyBlocks } from '../controllers/sendController';
import { UALContext } from 'ual-reactjs-renderer';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { stakeAssets } from '../controllers/sendController';
import { useHorizontalScroll } from '../Hooks/useSideScroll';
import { bulkUnstake, bulkCollectTax, collectTax, queueUnstake, cancelUnstake, levelup, claimLevelup, speedLevelup, repair } from '../controllers/missionsController';
import Countdown from '../Countdown/Countdown';
import { useNavigate } from "react-router-dom";
import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
const MATERIALS_TEMPLATES=["614598","614599","614600"]

const SweetalertModal = ({slot,onValue,array,callback,ipfs}) =>{
  const [selected,setSelection] = useState(null)
  const [fill,setFill] = useState("614599")
  useEffect(() => { onValue(selected) }, [selected])
  useEffect(()=>{if (slot===1)setFill("614598");
  if (slot===2)setFill("614599");
  if (slot===3)setFill("614600");
}
  ,[slot])
  return (
      <div className='container-fluid' style={{"maxHeight":"500px"}}>
          <div  className="row row-cols-sm-2  mb-2" >
              {/* filter assets by template id */ array.filter((item)=> item.template.template_id === fill )
              .map((item)=>{return (
              <div key={item.asset_id} className="col d-flex pb-1 flex-column align-items-center">
                  <div onClick={()=>setSelection(item)} className={["nft-container",selected!==null && selected.asset_id===item.asset_id?"selected":""].join(" ")}>
                  <img  src={"https://"+ipfs+"/ipfs/"+item.data.img} alt="" />
                  </div>
                  <span>#{item.asset_id}</span>
              </div>)
              })
              }
          </div>
          </div>
  );
}


function Craft() {
  const {userData,getInventory,getStakedAssets,getSignupVerification,getAvailableToSteal} = useContext(GlobalStateContext)
  const ual = useContext(UALContext)
  const [slotOne,setSlotOne] = useState(null)
  const [slotTwo,setSlotTwo] = useState(null)
  const [slotThree,setSlotThree] = useState(null)
  const [resultSlot,setResultSlot] = useState(null)
  const SelectorModal = withReactContent(Swal)
  const navigate = useNavigate()


  useEffect(()=>{
    if(slotOne!==null && slotTwo!==null && slotThree!==null){
      setResultSlot(1)
    }
  },
  [slotOne,slotTwo,slotThree])

let openSlot = (slot_id) => {
  let selectedFromModal;
  let inv = userData.inventory
  let filtered = inv;
  if (slot_id===1 && slotOne!==null)filtered = inv.filter((item)=>item.asset_id!==slotOne.asset_id);
  if (slot_id===2 && slotTwo!==null)filtered = inv.filter((item)=>item.asset_id!==slotTwo.asset_id);
  if (slot_id===3 && slotThree!==null)filtered = inv.filter((item)=>item.asset_id!==slotThree.asset_id);
    SelectorModal.fire({
      title: 'Choose asset!',
      html:(<SweetalertModal slot ={slot_id} ipfs={userData.ipfs} onValue={value => { selectedFromModal = value }} array={filtered}/>),
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      preConfirm: (input)=>{
        if(selectedFromModal!==null){
          if (slot_id===1) setSlotOne(selectedFromModal);
          if (slot_id===2) setSlotTwo(selectedFromModal);
          if (slot_id===3) setSlotThree(selectedFromModal);
        }
      }
    })
  }

  function resetSlots(){
    setSlotOne(null)
    setSlotTwo(null)
    setSlotThree(null)
    setResultSlot(null)
  }

  function craft(){
    transferNeftyBlocks(ual,[slotOne.asset_id,slotTwo.asset_id,slotThree.asset_id],19984)
    .then(()=>getInventory())
    .then(()=>resetSlots())
  }



    return (
        <div className="content-body">
        <div className="container-fluid">
        <div className="row">
            <div>
              <button onClick={()=>navigate(-1)} className='fa-solid btn fa-arrow-left'></button>
              </div>
            </div>
          <div className="row row-cols-1 row-cols-sm-3 row-cols-md-6">

          <div className="col " style={{"minWidth":"125px","cursor":"pointer"}} onClick={()=>openSlot(1)}>
              <div className="card mt-3" style={{maxWidth:"200px",maxHeight:"250px"}}>
                    <div className="picture_nft">
                      {slotOne===null || slotOne===undefined?<img className="img-fluid" alt='add-nft' src="image/workspace/add_NFT.png"/>:
                      <img className="img-fluid" alt='add-nft' src={"https://"+userData.ipfs+"/ipfs/"+slotOne.data.img}/>}
                    </div>
              </div>
            </div>

          <div className="col f-2 d-flex justify-content-center" style={{"fontSize":"60px","textAlign":"center","alignSelf":"center" , "maxWidth":"fit-content"}}>
            <span>+</span>
            </div>

          <div className="col " style={{"minWidth":"125px","cursor":"pointer"}} onClick={()=>openSlot(2)}>
              <div className="card mt-3" style={{maxWidth:"200px",maxHeight:"250px"}}>
              <div className="picture_nft">
                      {slotTwo===null || slotTwo===undefined?<img className="img-fluid" alt='add-nft' src="image/workspace/add_NFT.png"/>:
                      <img className="img-fluid" alt='add-nft' src={"https://"+userData.ipfs+"/ipfs/"+slotTwo.data.img}/>}
                    </div>
              </div>
            </div>
          <div className="col f-2 d-flex justify-content-center" style={{"fontSize":"60px","textAlign":"center","alignSelf":"center", "maxWidth":"fit-content"}}>
          <span>+</span>
          </div>

          <div className="col" style={{"minWidth":"125px","cursor":"pointer"}} onClick={()=>openSlot(3)}>
              <div className="card mt-3" style={{maxWidth:"200px",maxHeight:"250px"}}>
              <div className="picture_nft">
                      {slotThree===null || slotThree===undefined?<img className="img-fluid" alt='add-nft' src="image/workspace/add_NFT.png"/>:
                      <img className="img-fluid" alt='add-nft' src={"https://"+userData.ipfs+"/ipfs/"+slotThree.data.img}/>}
                    </div>
              </div>
            </div>
          <div className="col f-2 d-flex justify-content-center" style={{"fontSize":"60px","textAlign":"center","alignSelf":"center" , "maxWidth":"fit-content"}}>
            <span>=</span>
            </div>

          <div className="col" style={{"minWidth":"125px"}}>
              <div className="card mt-3" style={{maxWidth:"200px",maxHeight:"250px"}}>
              <div className="picture_nft">
                      {resultSlot===null || resultSlot===undefined?<img className="img-fluid" alt='add-nft' src="image/workspace/add_NFT.png"/>:
                      <img className="img-fluid" alt='add-nft' src={"https://"+userData.ipfs+"/ipfs/"+"QmPK6SEThahj4zJ8EDABoSeF7h6VauwsKtHtf88WTp2arF"}/>}
                    </div>
              </div>
            </div>
            <div className="col d-flex justify-content-center align-self-center ">
              <div onClick={()=>craft()} className={["justify-content-center","col","btn","btn-primary",resultSlot===null?"disabled":""].join(" ")}>Craft</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  export default Craft;