import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UALContext } from "ual-reactjs-renderer";
import { GlobalStateContext } from "../Contexts/stateContext";
import gold from "../../dcyc-gold.png"
import egg from "../../token-91.png"


//import '../../styles/hakkv_.css'
function NavBar({callback,sidebar}){
  const [dropdownIsOpen,setDropdown] = useState(false)
  const [currentLocation,setLocation] = useState('/')
  const [currentUser,setCurrentUser] = useState(null)
  const [showGold,changeGold] = useState(true)
  const ual = useContext(UALContext)
  const {userData} = useContext(GlobalStateContext)
  const location = useLocation()
  async function getBalance(user){
    let info = await ual.activeUser.rpc.get_account(user)
  }
  useEffect(()=>{
    setCurrentUser(ual.activeUser.accountName)
  },[ual.activeUser])

  let swapToken =  () =>{
    changeGold(!showGold)
  }

  useEffect(()=>{
    if(currentUser){
      getBalance(currentUser)
    }
  },[currentUser])

  useEffect(()=>{
    setLocation(location.pathname)
  },[location])
    return (
      
      <>
        <div className="nav-header">
          <a href="#" className="brand-logo">
            <img className="logo-abbr" src={/*"image/logo/dc_text_logo.png"*/"image/halloween/halloween-text-2-comp.png"}/>
          </a>
          <div className="nav-control" onClick={()=>callback()}>
            <div className={["hamburger",!sidebar?"is-active":""].join(" ")}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        </div>
        <div className="header">
          <div className="header-content">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse justify-content-between">
                <div className="header-left">
                  <div className="dashboard_bar">{currentLocation=='/'?"Workspace":currentLocation.slice(1).replace(/\b(\w)/g, s => s.toUpperCase())}</div>
                </div>
                <ul className="navbar-nav header-right">
                  <li className="nav-item recipe d-sm-none">
                    <div onClick={()=>swapToken()} style={{cursor:"pointer"}} className="btn btn-head btn-rounded">
                    {showGold?<><img style={{"width":"30px","height":"30px"}}  alt='Gold token' src={gold} /> 
                    <span> {userData.internalEggcoinsBalance}</span></>
                    :
                    <><img style={{"width":"30px","height":"30px"}}  alt='egg token' src={egg}/> 
                    <span> {Math.floor(userData.internalEggsBalance*100)/100}</span></>
                    }
                    </div>
                  </li>
                  <li className="nav-item recipe d-none d-sm-flex">
                    <div  style={{cursor:"pointer"}} className="btn btn-head btn-rounded">
                    <img style={{"width":"30px","height":"30px"}}  alt='Gold token' src={gold} /> 
                    <span> {userData.internalEggcoinsBalance}</span>
                    </div>
                  </li>
                  <li className="nav-item recipe d-none d-sm-flex">
                    <div style={{cursor:"pointer"}} className="btn btn-head btn-rounded">
                    <img style={{"width":"30px","height":"30px"}}  alt='egg token' src={egg}/> 
                    <span> {Math.floor(userData.internalEggsBalance*100)/100}</span>
                    </div>
                  </li>
                  <li className="nav-item dropdown header-profile">
                    <a style={{cursor:"pointer"}} onClick={()=>setDropdown(!dropdownIsOpen)} className={["nav-link",dropdownIsOpen?"show":""].join(" ")} data-bs-toggle="dropdown" aria-expanded={dropdownIsOpen}>
                      <img src="image/profile/rooster_.png" width="20"/>
                      <div className="header-info">
                        <span>{currentUser==null?"":currentUser} <i className="fa fa-caret-down ms-3"></i>
                        </span>
                      </div>
                    </a>
                    <div className={["dropdown-menu","dropdown-menu-end",dropdownIsOpen?"show":""].join(" ")} {...(dropdownIsOpen?{'data-bs-popper':"none"}:null)}>
                      <Link to="auth" onClick={()=>ual.logout()} className="dropdown-item ai-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                          <polyline points="16 17 21 12 16 7"></polyline>
                          <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                        <span className="ms-2">Logout</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        </>
        
        
    );
  }
  export default NavBar;