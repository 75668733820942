import { useContext } from 'react';
import { GlobalStateContext } from '../Contexts/stateContext';
import "../../styles/mission_page.css"
import { UALContext } from 'ual-reactjs-renderer';
import { useEffect, useId } from 'react';
import { useState } from 'react';
import Countdown from '../Countdown/Countdown';
import {missionStart, missionGo, missionComplete,missionCollect,missionAbort,missionCancel, bulkMissionGoWeapon} from '../controllers/missionsController'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const MISSION_A = "North";
const MISSION_B = "West";
const MISSION_C = "East";
const MISSION_D = "South";

function AssetPage() {
  const ual = useContext(UALContext)
  const {userData, getStakedAssets, getSignupVerification} = useContext(GlobalStateContext)
  const [currentAsset,setAsset] = useState(undefined)
  const [bulbsArray,setBulbs] = useState([0,0,0,0,0,0,0,0]) // 1-north(mission A) | 2-east(mission C) | 3-west(mission B) | 4-south(mission D)
  const [completeCountdowns,addCompleteCountdown] = useState(0)
  const [currentTime,updateCurrentTime] = useState(new Date().getTime()/1000)
  const navigate = useNavigate()
  const params = useParams()
  useEffect(()=>{
    setAsset(
      userData.stakedChickens.find(chicken=>chicken.asset_id === params.assetId)
      //userData.stakedChickens.map((chicken)=> {return {...chicken,associatedMission:missions.rows.find((mission)=>mission.index===chicken.asset_id)}})
      )
      
  },[userData.stakedChickens])

  useEffect(()=>{
    updateCurrentTime(new Date().getTime()/1000)
  },[completeCountdowns])

  useEffect(()=>{
    if(currentAsset!==undefined && currentAsset.associatedMission!==undefined){
      setBulbs([0,0,0,0,0,0,0,0].map((mission,index)=>{
        let current_mission = userData.missionsList.find(mission=> mission.mission_id === currentAsset.associatedMission.completed_missions[index])
        switch(/*currentAsset.associatedMission.completed_missions[index]*/ current_mission!==undefined?current_mission.info[0].value:0){
          case (MISSION_A):
            return 1
            break;
          case(MISSION_B):
            return 3
            break;
          case(MISSION_C):
            return 2
            break;
          case(MISSION_D):
            return 4
            break;
          default:
            return 0
            
        }
      }))
    }
  },[currentAsset])


  function update(){
    addCompleteCountdown((countdown)=>countdown+1)
  }

  let start = (mission_id) =>{
    missionStart(ual,[currentAsset.asset_id]).then(()=>missionGo(ual,currentAsset.asset_id,mission_id))
    .then(()=>Swal.fire({
      title: 'Processing transaction!',
      timer: 2000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getStakedAssets())
    .then(()=>getSignupVerification())
  }
  let go = (mission_id) =>{
    if (currentAsset.associatedMission.asset_ids.length>1){
      const ids = [mission_id];
      let jet = false;
      if (currentAsset.associatedMission.asset_ids[1] === "618025"){ids = Array(2).fill(mission_id);jet=true}
      if (currentAsset.associatedMission.asset_ids[1] === "618026"){ids = Array(4).fill(mission_id);jet=true}
      if (currentAsset.associatedMission.asset_ids[1] === "618027"){ids = Array(8).fill(mission_id);jet=true}
      if (jet === true){
        bulkMissionGoWeapon(ual,[{weapon_nft:currentAsset.associatedMission.asset_ids[1],user_mission_id:currentAsset.asset_id,mission_ids:ids}])
      .then(()=>Swal.fire({
        title: 'Processing transaction!',
        timer: 3000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
      }))
      .then(()=>getStakedAssets())
      .then(()=>getSignupVerification())
    }
    if (jet === false){
      missionGo(ual,currentAsset.asset_id,mission_id)
    .then(()=>Swal.fire({
      title: 'Processing transaction!',
      timer: 3000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getStakedAssets())
    .then(()=>getSignupVerification())
    }
      }
      
    else(
    missionGo(ual,currentAsset.asset_id,mission_id)
    .then(()=>Swal.fire({
      title: 'Processing transaction!',
      timer: 2000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getStakedAssets())
    .then(()=>getSignupVerification())
    )
  }

  let useWeapon = (mission_id) =>{
    bulkMissionGoWeapon(ual,currentAsset.asset_id,mission_id)
    .then(()=>Swal.fire({
      title: 'Processing transaction!',
      timer: 2000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getStakedAssets())
    .then(()=>getSignupVerification())
  }

  let abort = (abort_cost) =>{
    Swal.fire({
      title: 'Are you sure you want to abort current mission?',
      text: "It will cost you "+abort_cost,
      icon: 'warning',
      showCancelButton: true,
    }).then((result)=>{
      if (result.isConfirmed){
        missionAbort(ual,currentAsset.asset_id).then(()=>getStakedAssets())
      }
    })
    //
  }

  let completeMission = () =>{
    missionComplete(ual,currentAsset.asset_id)
  }

  let collectReward = () =>{
    missionCollect(ual,currentAsset.asset_id)
  }

  let cancelMission = () =>{
    missionCancel(ual,currentAsset.asset_id)
    .then(()=>Swal.fire({
      title: 'Processing transaction!',
      timer: 5000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>getStakedAssets())
  }

  let completeAndCollect = () =>{
    missionComplete(ual,currentAsset.asset_id)
    .then(()=>Swal.fire({
      title: 'Generating reward!',
      timer: 2000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }))
    .then(()=>missionCollect(ual,currentAsset.asset_id)).then(()=>getStakedAssets())
  }
  
    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">Asset description.</ol>
          </div>
          <div className="row">
            <div>
              <button onClick={()=>navigate(-1)} className='fa-solid btn fa-arrow-left'></button>
              </div>
            </div>
          <div className="card flex-row" style={{"height":"800px"}}>
            
            <div className="d-none d-sm-none d-md-block col-2">
            <div className="delay-items pt-5 justify-content-center">
                        <div className="delay-item">
                          <span className="num fs-6">{currentAsset!==undefined&& "#"+currentAsset.asset_id}</span>
                        </div>
                      </div>
            <div className="stake_mb">
                    <div className="dcactives">
                      <div className="picture_nft">
                        {currentAsset!==undefined&& < img className="img-fluid" alt='nft image' src={"https://ipfs.io/ipfs/"+currentAsset.data.img}/>}
                      </div>
                    </div>
                  </div>
                  {currentAsset!==undefined && currentAsset.associatedMission!==undefined && currentAsset.associatedMission.asset_ids.length>=2 &&
                    (
                      <div className="stake_mb">
                    <div className="dcactives">
                      <div className="picture_nft">
                        < img className="img-fluid" alt='nft image' src={"https://ipfs.io/ipfs/"+ userData.stakedChickens.find(weapon=>weapon.asset_id === currentAsset.associatedMission.asset_ids[1]).data.img}/>
                      </div>
                    </div>
                  </div>
                    )
                  }
            </div>
            <div className="col missions-col">
                <div className="row current-mission-row">
                {currentAsset&& currentAsset.associatedMission!==undefined&& currentAsset.associatedMission.completed_missions.length===0&& <div onClick={()=>cancelMission()} className="start-mission fs-5 btn btn-primary">Cancel missions</div>}
                  {(currentAsset!==undefined && currentAsset.associatedMission!==undefined && currentAsset.associatedMission.completed_missions.length>0)&&
                    <div className="container">
                      <div className="mission-div pt-2 ms-2 me-2">
                        <div className="mission-title h4">
                        Current mission: {userData.missionsList.length>0?
                        (currentAsset.associatedMission.completed_missions.length>1?userData.missionsList.find(mission=> mission.mission_id === currentAsset.associatedMission.completed_missions[currentAsset.associatedMission.completed_missions.length-1]).info[0].value:
                        userData.missionsList.find(mission=> mission.mission_id === currentAsset.associatedMission.completed_missions[0]).info[0].value):"Loading"}
                        </div>
                        <hr />
                        <div className="mission-status h5">
                          Status: {Math.round(new Date().getTime()/1000)>Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000)?"Complete":"In progress"}
                        </div>
                        <div style={{"position":"relative"}} className="img-container d-none d-sm-block">
                        <div style={{"position":"absolute","zIndex":1}} className="">
                          <Countdown callback={update} sec={Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000-new Date().getTime()/1000)}/>
                          </div>
                          <div className="sliding-background">

                          </div>
                          
                        </div>
                        <hr />
                        <div className="row row-cols-1 justify-content-center">
                        <div className="machine-section d-none d-sm-flex col">
                          <div className="bulbs-section">
                          {bulbsArray.map((item,index)=>{return(
                            
                            <>
                            {item===1&&<div className="bulb-north">
                            </div>}
                            {item===0&&<div  className="bulb-off">
                            </div>}
                            {item===2&&<div  className="bulb-east">
                            </div>}
                            {item===3&&<div  className="bulb-west">
                            </div>}
                            {item===4&&<div  className="bulb-south">
                            </div>}
                            </>
                          )})}

                          
                          </div>
                        </div>
                        <div className="mobile-machine text-center fs-4 d-sm-none">
                          <div className="d-flex justify-content-center">
                        <Countdown callback={update} sec={Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000-new Date().getTime()/1000)<=0?0:Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000-new Date().getTime()/1000)}/>
                          </div>
                            <span>Missions: {currentAsset.associatedMission.completed_missions.length}/8</span>
                          </div>
                        <div className="vertical-machine d-md-none">
                        </div>
                        <div className="reward-section col text-center">
                            {(currentAsset.associatedMission.completed_missions.length>=8 && Math.round(new Date().getTime()/1000)>Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000))&&<div onClick={()=>completeAndCollect()} className="btn btn-primary fs-5">Complete</div>}
                            {Math.round(new Date().getTime()/1000)>Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000)&&<div onClick={()=>abort(userData.missionsList.find(mission=> mission.mission_id === currentAsset.associatedMission.completed_missions[currentAsset.associatedMission.completed_missions.length-1]).mission_abort_cost[0].quantity)} className="btn btn-primary fs-5">Abort</div>}
                        </div>
                        </div>
                      </div>
                    </div>}
                </div>
                {((currentAsset!==undefined && (currentAsset.associatedMission==undefined||currentAsset.associatedMission.length===0)) || (currentAsset!==undefined && currentAsset.associatedMission!=undefined && Math.round(new Date().getTime()/1000)>Math.round(new Date(currentAsset.associatedMission.available_at+"Z").getTime()/1000))) &&
                <div className="row row-cols-1 row-cols-sm-2 missions-row gx-5 gy-5 ps-4 pe-4 pt-2 pb-2">
                  {userData.missionsList.map(mission=>{ 
                    
                    return (
                      ((currentAsset.associatedMission!==undefined && currentAsset.associatedMission.asset_ids.length>1 && mission.allowlist[1]!==undefined && mission.allowlist[1][0]!==undefined &&  String(mission.allowlist[1][0].template_id)=== userData.stakedChickens.find(weapon=>weapon.asset_id === currentAsset.associatedMission.asset_ids[1]).template.template_id  &&  mission.allowlist[1][0].level_req === userData.stakedChickens.find(weapon=>weapon.asset_id === currentAsset.associatedMission.asset_ids[1]).data.level && mission.allowlist[0][0].level_req === currentAsset.data.level && String(mission.allowlist[0][0].template_id)===currentAsset.template.template_id)||(currentAsset.associatedMission!==undefined && currentAsset.associatedMission.asset_ids.length==1 && mission.allowlist[1]===undefined && mission.allowlist[0][0].level_req === currentAsset.data.level && String(mission.allowlist[0][0].template_id)===currentAsset.template.template_id) || (currentAsset.associatedMission!==undefined && currentAsset.associatedMission.asset_ids.length==1 && mission.allowlist[1]===undefined && mission.allowlist[0][0].level_req===0 && currentAsset.data.level===undefined ) || (currentAsset.associatedMission===undefined && mission.allowlist[1]===undefined && mission.allowlist[0][0].level_req === currentAsset.data.level && String(mission.allowlist[0][0].template_id)===currentAsset.template.template_id))&&
                      (<div key={mission.mission_id} className="mission col text-center">
                        <div className="">
                          <span className='h4'>{mission.info[0].value}</span>
                          <hr />
                          <div>Level: {mission.allowlist[0][0].level_req}</div>
                          <div>Mission duration:{mission.mission_duration <=3600?Math.floor( mission.mission_duration/60)+" min":Math.floor(mission.mission_duration/3600)+" hours"}</div>
                          <div>Mission cost: {mission.mission_cost[0].quantity.split(" ")[0]>0?mission.mission_cost[0].quantity.split(" ")[0]+" GOLD":"Free"} {mission.mission_cost.length>1&&"+ "+mission.mission_cost[1].quantity}</div>
                          <div>Reward: {mission.mission_reward_success[0].quantity.split(" ")[0]} GOLD</div>
                          <div>Mission success rate: {(mission.mission_odds===0 && mission.mission_odds_total===0)?100:Math.floor(mission.mission_odds/mission.mission_odds_total*100)} %</div>
                          <div>Stolen if mission fail: {(mission.pools_fail.length===0)?0:mission.pools_fail[0].asset.quantity.split(" ")[0] + " GOLD"}</div>
                          <hr />
                          {currentAsset&& <div onClick={()=>currentAsset.associatedMission!==undefined?go(mission.mission_id):start(mission.mission_id)} className="start-mission fs-5 btn btn-primary">{currentAsset.associatedMission!==undefined?"Continue":"Start"}</div>}
                          
                        </div>
                      </div>
                    )
                    )
                    
                    })}
                </div>
                }
            </div>
          </div>
        </div>
        </div>
    );
  }
  export default AssetPage;