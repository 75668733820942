import { transferWax, transferAsset } from '../controllers/sendController';
import { useContext,useState,useEffect } from 'react';
import "../../styles/exchange_page.css"
import { swap,withdraw } from '../controllers/missionsController';
import { GlobalStateContext } from '../Contexts/stateContext';
import { UALContext } from 'ual-reactjs-renderer';
import gold from "../../dcyc-gold.png"
import egg from "../../token-91.png"
import Swal from 'sweetalert2';
const NUM = 100000000
//number.slice(0,enter.toString().length)+"."+number.slice(enter.toString().length)
function ExchangePage() {
  const ual = useContext(UALContext)
  const {userData, getInventory, getSignupVerification, getSwapPool,getBalance} = useContext(GlobalStateContext)
  const [withdrawAmount,setWithdrawAmount] = useState(0)
  const [expected,setExpected] = useState(0)
  const [eggprice,setEggprice] = useState(0.0099)
  const [slippage,setSlippage] = useState(0.1)
  const [eggcoinprice,setEggcoinprice] = useState(99)
  const [withdrawIsEgg,changeWithdraw] = useState(true)

  useEffect(()=>{
    if (userData.swapPool){
    setEggprice(userData.swapPool.asset_b.quantity.split(" ")[0]/userData.swapPool.asset_a.quantity.split(" ")[0])
    setEggcoinprice(userData.swapPool.asset_a.quantity.split(" ")[0]/userData.swapPool.asset_b.quantity.split(" ")[0])
    }
  },[userData.swapPool])

  useEffect(()=>{

    if(withdrawIsEgg && Number(withdrawAmount)>=userData.internalEggsBalance){
      
      setWithdrawAmount(userData.internalEggsBalance)

    }
    if(!withdrawIsEgg && Number(withdrawAmount)>=userData.internalWax){
      
      setWithdrawAmount(userData.internalWax)

    }
  },[withdrawAmount])
  const [swapAmount,setSwapAmount] = useState(0)
  const [isEGG,setEGG] = useState(true)
  let callSwap = () =>{
    if(isEGG){
      swap(ual,"dcyctokenswp",true,{"quantity":Number(swapAmount).toFixed(8)+" GOLD","contract":"dcyctokeneos"},{"quantity":expected+" EGG","contract":"dcyctokeneos"})
      
      .then(()=>getSwapPool()).then(()=>getSignupVerification())
    }
    else{
      swap(ual,"dcyctokenswp",false,{"quantity":Number(swapAmount).toFixed(8)+" EGG","contract":"dcyctokeneos"},{"quantity":expected+" GOLD","contract":"dcyctokeneos"})
      .then(()=>getSwapPool()).then(()=>getSignupVerification())
    }
  }

  useEffect(()=>{
    if (isEGG){
      // price-((slippage/100)*price) === expected
      setExpected((swapAmount*(eggprice-((slippage/100)*eggprice))).toFixed(8))
      //setExpected((swapAmount*Math.round(eggprice*10000)/10000).toFixed(8))
    }
    else{
      //setExpected((swapAmount*Math.round(eggcoinprice*100)/100).toFixed(8))
      setExpected((swapAmount*(eggcoinprice-((slippage/100)*eggcoinprice))).toFixed(8))
    }
  },[swapAmount,slippage])

  let handleChange = (event) => {
    setWithdrawAmount(event.target.value)
  }

  let handleSlippage = (event) => {
    setSlippage(event.target.value)
  }

  let handleSwapChange = (event) => {
    setSwapAmount(event.target.value)
  }

  let changeTokens = () =>{
    setEGG(!isEGG)
    let swap = swapAmount
    let expect = expected
    //setExpected(swap)
    setSwapAmount(expect)
  }

  let handleChangeWithdraw = () =>{
    changeWithdraw(!withdrawIsEgg)
    setWithdrawAmount(0)
  }

  let handleSubmit = () => {
    let dotNumber = Number(withdrawAmount).toFixed(8)
    if (withdrawIsEgg){
      withdraw(ual,dotNumber,"EGG")
      .then(()=>getSignupVerification())
      .then(()=>getBalance())
    }
    else {
      withdraw(ual,dotNumber,"WAX")
      .then(()=>getSignupVerification())
      .then(()=>getBalance())
    }
    
  }
    return (
        <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">Exchange description.</ol>
          </div>
          <div style={{"position":"absolute","display":"flex","maxWidth":"100%","minWidth":"-webkit-fill-available","justifyContent":"space-around","zIndex":"-10"}} className="col halloween-container">

          <div className="col" style={{"backgroundImage":`url("image/halloween/ghost-chicken.png")`,"backgroundSize":"contain","position":"relative","height":"150px","max-width":"150px","zIndex":"-1","backgroundRepeat":"no-repeat","marginTop":"150px"}}></div>
          <div className="col" style={{"backgroundImage":`url("image/halloween/grumpy-ghost.png")`,"backgroundSize":"contain","position":"relative","height":"150px","max-width":"150px","zIndex":"-1","backgroundRepeat":"no-repeat","marginTop":"150px"}}></div>

          </div>
          <div className="row chailce-font internal-balance mt-2 text-center justify-content-center">
            <div className="balance h1">
              Internal balance:
            </div>
            <div className="swap-rate col-2 h2">
              <div className='egg-token media'>
                <span title="EGG" className='me-3'>
                  <img alt='egg token' src={egg} />
                </span>
                 {Math.floor(userData.internalEggsBalance*100000)/100000}
              </div>
              <div className='eggcoins-token media mt-1'>
                <span title="GOLD" className="me-3">
                  <img alt='gold token' src={gold} />
                </span>
                {userData.internalEggcoinsBalance}
              </div>
            </div>
          </div>
          <div className="row flex-column mb-5 mt-5 text-center chailce-font">
            <div className="swap-rate h1">
              Current swap rate
            </div>
            <div className="swap-rate h2">
              {isEGG?"1 GOLD ≈ "+Math.floor(eggprice*10000)/10000+" $EGG":"1 $EGG ≈ "+Number(eggcoinprice).toFixed(8)+" GOLD"}
            </div>
          </div>
          <div className="row">
            <div className="col swap-section">
              <div className="row">
                <div className="col">
                  <div className="mb-2">
                  <label htmlFor="amountInput" className="form-label">{isEGG?"GOLD amount":"$EGG amount"}</label>
                  <input onChange={handleSwapChange} type="number" className="form-control" id="amountInput"  min="0" value={swapAmount} />
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="slippage" className="form-label">slippage %</label>
                  <input onChange={handleSlippage} type="number" className="form-control" id="slippage" min={0.1}  value={slippage} step={0.1} max={50} />
                </div>
                <div className="col">
                  <label htmlFor="expectedAmount" className="form-label">{isEGG?"$EGG amount":"GOLD amount"}</label>
                  <input type="number" className="form-control" id="expectedAmount" min={0}  value={expected} disabled readOnly/>
                </div>
                <div className="btn-group chailce-font" role="group">
                <div onClick={()=>callSwap()} className="btn btn-primary font-21">Swap</div>
                <div onClick={()=>changeTokens()} className="btn btn-primary font-21">Change</div>
                </div>
              </div>
            </div>
            <div className="col withdraw-section">
              <label htmlFor="withdrawdAmount" className="form-label">{withdrawIsEgg?"$EGG":"WAX"} amount</label>
              <input type="number" value={withdrawAmount} onChange={handleChange} className="form-control" id="withdrawAmount" min={0}/>
              <div className="btn-group chailce-font" role="group">
              <div onClick={()=>handleSubmit()} className="btn btn-primary font-21 mt-2 chailce-font">Withdraw</div>
              <div onClick={()=>handleChangeWithdraw()} className="btn btn-primary mt-2 font-21">Change</div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
  export default ExchangePage;