import {ActionBuilder} from './ActionBuilder';
import Swal from 'sweetalert2'
const CONTRACT_NAME = "dcycmissions"
const MISSION_START = "missionstart" // owner, asset_ids
const MISSION_GO = "missiongo" // owner, user_mission_id, mission_id
const MISSION_CANCEL = "missioncncl" // owner, user_mission_id
const MISSION_COMPLETE = "missioncomp" //owner, user_mission_id
const MISSION_COLLECT = "missioncoll" //owner, user_mission_id
const MISSION_ABORT = "missionabrt" // owner, user_mission_id
const SIGNUP_ACTION = "signup"
const UNSTAKE_ACTION = "unstake"
const QUEUE_UNSTAKE = "queueunstake"
const COLLECT_TAX = "collecttax"
const WITHDRAW_ACTION = "withdraw"
const SWAP_ACTION = "swap"
const EGGCOIN_CONTRACT = "dcyctokeneos"
const EGGCOIN_SYMBOL = "EGG"
const LEVELUP_ACTION = 'levelup'
const SPEED_LEVEL = "speedlevel"
const BUY_ACTION = "buylisting"
const CANCEL_QUEUE = "cnclunstake"
const LVLUP_QUEUE = "queuelvlup"
const LVLUP_SKIP = "skiplvlup"
const LVLUP_CLAIM = "claimlvlup"
const WEAPON_USE = "useweapon"
const WEAPON_REPAIR = "repair"
let ATOMICASSETS_TRANSFER_ACTION = new ActionBuilder().setActionAccount("atomicassets").setActionName("transfer")
//const ACTIVE_PERMISSION = [{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}]
let CLAIM_ACTION = new ActionBuilder().setActionAccount("dcycstealing").setActionName("claim")
let CLAIM_STOLENNFT = new ActionBuilder().setActionAccount("dcycstealing").setActionName("claimstolen")
/*
 Transaction structure
const demoTransaction = {
    actions: [{
      account: 'eosio.token',
      name: 'transfer',
      authorization: [{
        actor: 'imonlytestin', // use account that was logged in
        permission: 'active',
      }],
      data: {
        from: 'imonlytestin', // use account that was logged in
        to: 'eosio.token',
        quantity: '1.00000000 WAX',
        memo: 'testing',
      },
    }],
  }*/
//CLAIM_ACTION.setData({owner:"imonlytestin",roll:asset_id}).build()
export async function missionStart(ual,staked_assets) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_START)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_ids:staked_assets})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function missionGo(ual,user_mission_id,mission_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_GO)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_id,mission_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }


  export async function repair(ual,asset_id,amount) {
    let transaction;
    try {
      if (amount<=255){
        transaction = await ual.activeUser.signTransaction({actions:[
          new ActionBuilder().setActionAccount(CONTRACT_NAME)
          .setActionName(WEAPON_REPAIR)
          .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
          .setData({owner:ual.activeUser.accountName,asset_id,amount})
          .build()
        ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
    }
    else {
        let n = parseInt(amount/255)
        let more = amount%255
        for (let i =0 ;i<n;i++){
          transaction = await ual.activeUser.signTransaction({actions:[
            new ActionBuilder().setActionAccount(CONTRACT_NAME)
            .setActionName(WEAPON_REPAIR)
            .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
            .setData({owner:ual.activeUser.accountName,asset_id,amount:255})
            .build()
          ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
        }

        if(more>0){
          await ual.activeUser.signTransaction({actions:[
            new ActionBuilder().setActionAccount(CONTRACT_NAME)
            .setActionName(WEAPON_REPAIR)
            .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
            .setData({owner:ual.activeUser.accountName,asset_id,amount:more})
            .build()
          ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
        }
    }

      
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }



  /**
   * 
   * @param {*} ual 
   * @param {*} weapon_nft 
   * @param {*} user_mission_id 
   * @param {[String]} mission_ids 
   */
  export async function bulkMissionGoWeapon(ual,assetsList) {
    let assets = []
    try {
      assetsList.forEach((asset) => assets.push(
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(WEAPON_USE)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,weapon_nft:asset.weapon_nft,user_mission_id:asset.user_mission_id,mission_ids:asset.mission_ids})
        .build()
      ))
      let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function buyListing(ual,listing_id,amount) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(BUY_ACTION)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,listing_id,amount})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function collectTax(ual,asset_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(COLLECT_TAX)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id,fox_weapon_id:0})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      let tokenbals = transaction.transaction.processed.action_traces[0].inline_traces.filter((trace)=>trace.act.name === "logtokenbal")
      let arrOfEggs = tokenbals.filter((bal)=>bal.act.data.balance_dif.quantity.split(" ")[1]==="EGG")
      let arrOfGold = tokenbals.filter((bal)=>bal.act.data.balance_dif.quantity.split(" ")[1]==="GOLD")
      let collectedEgg =  arrOfEggs.map((item)=>parseFloat(item.act.data.balance_dif.quantity.split(" ")[0]))
      let collectedGold = arrOfGold.map((item)=>parseFloat(item.act.data.balance_dif.quantity.split(" ")[0]))
      //find quantity EGG and quantity GOLD
      let totalEgg = collectedEgg.reduce((partialSum, a) => partialSum + a, 0)
      let totalGold = collectedGold.reduce((partialSum, a) => partialSum + a, 0)
      Swal.mixin({
        toast: !0,
        position: "bottom-start",
        showConfirmButton: !1,
        timer: 5000,
        timerProgressBar: !0,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer)
            toast.addEventListener("mouseleave", Swal.resumeTimer)
        }
    }).fire({
        icon: "success",
        title: ["Collected",totalGold>0?totalGold+" GOLD":"",totalEgg>0?totalEgg+ " EGG":""].join(" ")
    })
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  /**
   * 
   * @param {[number]} asset_ids 
   */
  export async function bulkCollectTax(ual,asset_ids) {
    let assets = []
    let current_index = 0;
    let more = true;
    const ACTIONS = 40
    let transaction;
    let collectedTraces = []
    try {

      if (asset_ids.length>40){
        //let _slice_assets = []
        let n = parseInt(asset_ids.length/ACTIONS)
        let _more = asset_ids.length%ACTIONS
        console.log(n,_more)
        let loop1 = async function(){
         for (let i=0;i<n;i++){
           let _assets = []
           let sliced = asset_ids.slice(current_index,current_index+40)
           sliced.forEach((asset) => _assets.push(
            new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(COLLECT_TAX)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id:asset,fox_weapon_id:0})
        .build()
          ))
           let tr = await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
           collectedTraces = collectedTraces.concat(tr.transaction.processed.action_traces)
           current_index+=40
         }
        }
          
        let loop2 = async function(){
         if (_more>0){
           let _assets = []
           let more_actions = asset_ids.slice(asset_ids.length-_more,asset_ids.length)
           more_actions.forEach((asset) => _assets.push(
            new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(COLLECT_TAX)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id:asset,fox_weapon_id:0})
        .build()
          ))
           let tr = await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
           collectedTraces = collectedTraces.concat(tr.transaction.processed.action_traces)
         }
        }
        await loop1().then(()=>loop2())
  
        
      }
      else {
        let _assets =[]
        asset_ids.forEach((asset) => _assets.push(
          new ActionBuilder().setActionAccount(CONTRACT_NAME)
          .setActionName(COLLECT_TAX)
          .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
          .setData({owner:ual.activeUser.accountName,asset_id:asset,fox_weapon_id:0})
          .build()
       ))
        let tr = await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
        collectedTraces = collectedTraces.concat(tr.transaction.processed.action_traces)
      }



      /*asset_ids.forEach((asset) => assets.push(
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(COLLECT_TAX)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id:asset,fox_weapon_id:0})
        .build()
      ))*/
      //let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90, })
      //console.log(transaction)
      //console.log(transaction.transaction.processed.action_traces)
      let traces = collectedTraces //transaction.transaction.processed.action_traces
      //undefined is not an object (evaluating 't.act.data')
      let tokenbals = traces.map((trace)=>{return trace.inline_traces.filter((single_trace)=>single_trace.act.name === "logtokenbal")})
      tokenbals = tokenbals.flat()
      //let tokenbals = transaction.transaction.processed.action_traces[0].inline_traces.filter((trace)=>trace.act.name === "logtokenbal")
      let arrOfEggs = tokenbals.filter((bal)=>bal.act.data.balance_dif.quantity.split(" ")[1]==="EGG")
      let arrOfGold = tokenbals.filter((bal)=>bal.act.data.balance_dif.quantity.split(" ")[1]==="GOLD")
      let collectedEgg =  arrOfEggs.map((item)=>parseFloat(item.act.data.balance_dif.quantity.split(" ")[0]))
      let collectedGold = arrOfGold.map((item)=>parseFloat(item.act.data.balance_dif.quantity.split(" ")[0]))
      //find quantity EGG and quantity GOLD
      let totalEgg = collectedEgg.reduce((partialSum, a) => partialSum + a, 0)
      let totalGold = collectedGold.reduce((partialSum, a) => partialSum + a, 0)
      Swal.mixin({
        toast: !0,
        position: "bottom-start",
        showConfirmButton: !1,
        timer: 5000,
        timerProgressBar: !0,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer)
            toast.addEventListener("mouseleave", Swal.resumeTimer)
        }
    }).fire({
        icon: "success",
        title: ["Collected",totalGold>0?totalGold+" GOLD":"",totalEgg>0?totalEgg+ " EGG":""].join(" ")
    })
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function bulkMissionStart(ual,asset_ids) {
    let assets = []
    let current_index = 0;
    let more = true;
    const ACTIONS = 40
    let transaction;
    

    
    try {
     /* asset_ids.forEach((asset) => assets.push(
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_START)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_ids:[asset]})
        .build()
      ))
      let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })*/

      if (asset_ids.length>40){
        //let _slice_assets = []
        let n = parseInt(asset_ids.length/ACTIONS)
        let _more = asset_ids.length%ACTIONS
        console.log(n,_more)
        let loop1 = async function(){
         for (let i=0;i<n;i++){
           let _assets = []
           let sliced = asset_ids.slice(current_index,current_index+40)
           sliced.forEach((asset) => _assets.push(
            new ActionBuilder().setActionAccount(CONTRACT_NAME)
          .setActionName(MISSION_START)
          .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
          .setData({owner:ual.activeUser.accountName,asset_ids:[asset]})
          .build()
          ))
           await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
           current_index+=40
         }
        }
          
        let loop2 = async function(){
         if (_more>0){
           let _assets = []
           let more_actions = asset_ids.slice(asset_ids.length-_more,asset_ids.length)
           more_actions.forEach((asset) => _assets.push(
            new ActionBuilder().setActionAccount(CONTRACT_NAME)
          .setActionName(MISSION_START)
          .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
          .setData({owner:ual.activeUser.accountName,asset_ids:[asset]})
          .build()
          ))
           await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
         }
        }
        await loop1().then(()=>loop2())
  
        
      }
      else {
        let _assets =[]
        asset_ids.forEach((asset) => _assets.push(
          new ActionBuilder().setActionAccount(CONTRACT_NAME)
          .setActionName(MISSION_START)
          .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
          .setData({owner:ual.activeUser.accountName,asset_ids:[asset]})
          .build()
       ))
        transaction = await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
      }
      //console.log(transaction)
      //console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function bulkMissionGo(ual,assetsList) {
    let assets = []

    let current_index = 0;
    let more = true;
    const ACTIONS = 40
    let transaction;

    try {
      /* asset_ids.forEach((asset) => assets.push(
         new ActionBuilder().setActionAccount(CONTRACT_NAME)
         .setActionName(MISSION_START)
         .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
         .setData({owner:ual.activeUser.accountName,asset_ids:[asset]})
         .build()
       ))
       let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })*/
       if (assetsList.length>40){
         //let _slice_assets = []
         let n = parseInt(assetsList.length/ACTIONS)
         let _more = assetsList.length%ACTIONS
         console.log(n,_more)
         let loop1 = async function(){
          for (let i=0;i<n;i++){
            let _assets = []
            let sliced = assetsList.slice(current_index,current_index+40)
            sliced.forEach((asset) => _assets.push(
             new ActionBuilder().setActionAccount(CONTRACT_NAME)
             .setActionName(MISSION_GO)
             .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
             .setData({owner:ual.activeUser.accountName,user_mission_id:asset.asset_id,mission_id:asset.mission_id})
             .build()
           ))
            await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
            current_index+=40
          }
         }
           
         let loop2 = async function(){
          if (_more>0){
            let _assets = []
            let more_actions = assetsList.slice(assetsList.length-_more,assetsList.length)
            more_actions.forEach((asset) => _assets.push(
             new ActionBuilder().setActionAccount(CONTRACT_NAME)
             .setActionName(MISSION_GO)
             .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
             .setData({owner:ual.activeUser.accountName,user_mission_id:asset.asset_id,mission_id:asset.mission_id})
             .build()
           ))
            await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
          }
         }
         await loop1().then(()=>loop2())
   
         
       }
       else {
         let _assets =[]
         assetsList.forEach((asset) => _assets.push(
          new ActionBuilder().setActionAccount(CONTRACT_NAME)
          .setActionName(MISSION_GO)
          .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
          .setData({owner:ual.activeUser.accountName,user_mission_id:asset.asset_id,mission_id:asset.mission_id})
          .build()
        ))
         transaction = await ual.activeUser.signTransaction({actions:_assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
       }
       //console.log(transaction)
       //console.log(transaction.transaction.processed.action_traces)
       
     }
 catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function bulkMissionComplete(ual,user_mission_ids) {
    let assets = []
    
    try {
      user_mission_ids.forEach((asset) => assets.push(
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_COMPLETE)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_id:asset})
        .build()
      ))
      let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function bulkMissionCollect(ual,asset_ids) {
    let assets = []
    
    try {
      asset_ids.forEach((asset) => assets.push(
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_COLLECT)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_roll:asset})
        .build()
      ))
      let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      let traces = transaction.transaction.processed.action_traces
      let tokenbals = traces.map((trace)=>{return trace.inline_traces.filter((trace)=>trace.act.name === "logtokenbal")})
      tokenbals = tokenbals.flat()
      let collected = tokenbals.map((item)=>parseFloat(item.act.data.balance_dif.quantity.split(" ")[0]))
      let total = collected.reduce((partialSum, a) => partialSum + a, 0)
      Swal.mixin({
        toast: !0,
        position: "bottom-start",
        showConfirmButton: !1,
        timer: 5000,
        timerProgressBar: !0,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer)
            toast.addEventListener("mouseleave", Swal.resumeTimer)
        }
    }).fire({
        icon: "success",
        title: "Collected "+total+" GOLD"
    })
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  /**
   * 
   * @param {[number]} asset_ids 
   */
  export async function bulkUnstake(ual,asset_ids) {
    let assets = []
    
    try {
      asset_ids.forEach((asset) => assets.push(
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(UNSTAKE_ACTION)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id:asset})
        .build()
      ))
      let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }


  /**
   * 
   * @param {[number]} asset_ids 
   */
   export async function queueUnstake(ual,asset_ids) {
    let assets = []
    
    try {
      asset_ids.forEach((asset) => assets.push(
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(QUEUE_UNSTAKE)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id:asset})
        .build()
      ))
      let transaction = await ual.activeUser.signTransaction({actions:assets}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

  export async function cancelUnstake(ual,asset_id) {
    
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(CANCEL_QUEUE)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error.message)
    }
    
  }

 //sometimes roll is not ready
  export async function missionComplete(ual,user_mission_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_COMPLETE)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
    }
    
  }

  export async function missionCompleteWithoutRNG(ual,user_mission_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_COMPLETE)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_id})
        .build(),

        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_COLLECT)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
    }
    
  }

  // user_mission_roll == user_mission_id == staked_assets[0]
  export async function missionCollect(ual,user_mission_roll) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_COLLECT)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_roll})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 90 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      let tokenbals = transaction.transaction.processed.action_traces[0].inline_traces.filter((trace)=>trace.act.name === "logtokenbal")
      let collected = tokenbals.map((item)=>parseFloat(item.act.data.balance_dif.quantity.split(" ")[0]))
      let total = collected.reduce((partialSum, a) => partialSum + a, 0)
      Swal.mixin({
        toast: !0,
        position: "bottom-start",
        showConfirmButton: !1,
        timer: 5000,
        timerProgressBar: !0,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer)
            toast.addEventListener("mouseleave", Swal.resumeTimer)
        }
    }).fire({
        icon: "success",
        title: "Collected "+total+" GOLD"
    })
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
    }
    
  }
  // available if mission is not started
  export async function missionCancel(ual,user_mission_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_CANCEL)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  export async function missionAbort(ual,user_mission_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(MISSION_ABORT)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,user_mission_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  export async function signup(ual,referrer=".") {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(SIGNUP_ACTION)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,referrer})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
    
  }


  /**
   * 
   * @param {[number]} level_up_ids
   * @param {number} asset_id 
   */
  export async function levelup(ual,asset_id,level_up_ids) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(LVLUP_QUEUE)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id,level_up_ids})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  export async function speedLevelup(ual,asset_id,level_up_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(LVLUP_SKIP)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id,level_up_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  export async function claimLevelup(ual,asset_id) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(LVLUP_CLAIM)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,asset_id})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  /**
   * 
   * @param {*} ual 
   * @param {number} amount 1.00000000
   */
  export async function withdraw(ual,amount,symbol) {
    let tokenContract = symbol==="EGG"?EGGCOIN_CONTRACT:"eosio.token"
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(WITHDRAW_ACTION)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,token_contract:tokenContract,token:amount+" "+symbol})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      let tokenbals = transaction.transaction.processed.action_traces[0].inline_traces.filter((trace)=>trace.act.name === "transfer")
      let received = tokenbals[tokenbals.length-1].act.data.quantity
      console.log(tokenbals)
      Swal.fire({
        title: 'Processing transaction!',
        timer: 1500,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      .then(()=>{
        Swal.mixin({
          toast: !0,
          position: "bottom-start",
          showConfirmButton: !1,
          timer: 5000,
          timerProgressBar: !0,
          didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer)
              toast.addEventListener("mouseleave", Swal.resumeTimer)
          }
      }).fire({
          icon: "success",
          title: "Received "+received
      })
      })
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  export async function swap(ual,swap_pool,in_asset_a,input,expected) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[
        new ActionBuilder().setActionAccount(CONTRACT_NAME)
        .setActionName(SWAP_ACTION)
        .setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}])
        .setData({owner:ual.activeUser.accountName,swap_pool,in_asset_a,input,expected})
        .build()
      ]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      let tokenbals = transaction.transaction.processed.action_traces[transaction.transaction.processed.action_traces.length-1].inline_traces.filter((trace)=>trace.act.name === "logtokenbal")
      let plus = tokenbals.filter((trace) => trace.act.data.add === 1)[0].act.data.balance_dif.quantity
      let minus = tokenbals.filter((trace) => trace.act.data.add === 0)[0].act.data.balance_dif.quantity
      console.log(tokenbals)
      //let collected = tokenbals.map((item)=>parseFloat(item.act.data.balance_dif.quantity.split(" ")[0]))
    Swal.fire({
      title: 'Processing transaction!',
      timer: 2000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }).then(()=>{
      Swal.mixin({
        toast: !0,
        position: "bottom-start",
        showConfirmButton: !1,
        timer: 5000,
        timerProgressBar: !0,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer)
            toast.addEventListener("mouseleave", Swal.resumeTimer)
        }
    }).fire({
        icon: "success",
        html: "+"+plus+"<br/>"+"-"+minus
    })

    })
      
    } catch (error) {
      console.error(error)
      Swal.fire("error", error.message.toString(), "error");
      throw new Error(error)
    }
    
  }

  /*export async function transferWax(ual) {
    
    try {
      let transaction = await ual.activeUser.signTransaction({actions:[TRANSFER_ACTION.setAuthorization([{actor:ual.activeUser.accountName,permission:ual.activeUser.requestPermission}]).build()]}, { broadcast: true,blocksBehind: 3, expireSeconds: 30 })
      console.log(transaction)
      console.log(transaction.transaction.processed.action_traces)
      Swal.fire(
        'Good job!',
        'You clicked the button!',
        'success'
      )

    } catch (error) {
      console.error(error)
      Swal.fire("error", "an error occured", "error");
    }
    
  }*/